//External
import { Component } from "react";
import styled from "styled-components";

//Functions & Vars
import defaultStyles from "../../styles/defaultStyles";

//Components


export default class Form extends Component {
  onSubmitHandler = (e) => {
    e.preventDefault()
    this.props.onSubmit()
  }
  render() {
    return (
      <StyledForm onSubmit={(e) => this.onSubmitHandler(e)}>
        { this.props.children }
      </StyledForm>
    );
  }
}

const StyledForm = styled.form `
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: ${defaultStyles.element.padding.small};
`