import axios from "axios";
import Cookies from 'universal-cookie';

export default async function isUserAuthenticated(callback) {
  const cookie = new Cookies()
  if(!cookie.get("appToken")) return callback(false);
  const token = cookie.get("appToken")
  //axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  await axios
    .post(`${process.env.REACT_APP_API_URL}/auth`, {
      token
    })
    .then(res => {
      if(res.data.err) return callback(false)
      const token = res.data.data.token
      cookie.set("appToken", JSON.stringify(token), {
        path: "/",
        sameSite: true
      })
      callback(true)
    })
    .catch(err => {
      if(err) return callback(false)
    })
}