//External


//Functions & Vars
import StyledInput from "../../../styles/StyledInput"
import defaultStyles from "../../../styles/defaultStyles";
import generateUniqueElementId from "../../../lib/generateUniqueElementId";

//Components
import Icon from "../../helper/Icon";


export default function ControlledTextInput(props) {
  const {
    canCopy,
    name,
    value,
    disabled,
    onEnterKey,
    notice
  } = props;

  const elementId = generateUniqueElementId()

  function innerOnValueChange(e) {
    e.preventDefault()
    props.onValueChange(e.target.value)
  }

  function innerOnKeyDown(e) {
    if(e.key === "Enter") return props.onEnterKey(e)
  }

  function onCopyContent() {
    navigator.clipboard
      .writeText(value)
      .then(() => {
        return alert("Link kopiert")
      })
  }

  const noticeStyle = {
    display: "grid",
    gridTemplateColumns: "max-content 1fr",
    gridGap: ".25rem",
    fontSize: ".8rem",
    marginTop: ".5rem"
  }

  if(onEnterKey) {
    return (
      <StyledInput canCopy={canCopy}>
        <label
          htmlFor={elementId}
        >{ name }</label>
        <input
          id={elementId}
          type={"text"}
          value={value}
          onKeyDown={innerOnKeyDown}
          onChange={innerOnValueChange}
          placeholder={name}
          disabled={disabled}
        />
        {
          canCopy && (
            <span onClick={onCopyContent}><Icon icon={"link"} size={"1.25rem"} color={"#000"} /></span>
          )
        }
      </StyledInput>
    );
  }
  else {
    return (
      <StyledInput canCopy={canCopy}>
        <label
          htmlFor={elementId}
        >{ name }</label>
        <input
          id={elementId}
          type={"text"}
          value={value}
          onChange={innerOnValueChange}
          placeholder={name}
          disabled={disabled}
        />
        {
          canCopy && (
            <span onClick={onCopyContent}><Icon icon={"link"} size={"1.25rem"} color={"#000"} /></span>
          )
        }
        {
          notice && (
            <div style={noticeStyle}>
              <Icon icon={"info"} size={"1.1rem"} color={defaultStyles.color.theme.default} />
              <span>{ notice }</span>
            </div>
          )
        }
      </StyledInput>
    );
  }
}