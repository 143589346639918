//External


//Functions & Vars
import generateUniqueElementId from "../../../lib/generateUniqueElementId";
import StyledInput from "../../../styles/StyledInput"

//Components


export default function ControlledNumericInput(props) {

  const {
    name,
    min,
    max,
    disabled,
    value
  } = props;

  function innerOnChange(e) {
    if(!e.target.validity.valid) return 0;
    if(isNaN(e.target.value)) return 0
    if(e.target.value < min) return 0
    if(e.target.value > max) return 0
    console.log(parseInt(e.target.value))
    return props.onValueChange(parseInt(e.target.value))
  }

  const elementId = generateUniqueElementId()
  return (
    <StyledInput>
      <label
        htmlFor={elementId}
      >{ name }</label>
      <input
        id={elementId}
        type={"number"}
        placeholder={name}
        pattern={"[0-9]*"}
        min={min}
        max={max}
        onChange={innerOnChange}
        value={parseInt(value).toString()}
        disabled={disabled}
      />
    </StyledInput>
  );
}