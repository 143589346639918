import SubPage from "../../../components/ui/SubPage";
import Grid from "../../../components/helper/Grid";
import defaultStyles from "../../../styles/defaultStyles";
import Group from "../../../components/ui/Group";
import ControlledTextInput from "../../../components/form/controlled/TextInput";
import ControlledSelect from "../../../components/form/controlled/Select";
import ActionBar from "../../../components/ui/action_bar/ActionBar";
import ActionButton from "../../../components/ui/action_bar/ActionButton";
import Table from "../../../components/ui/table/Table";
import TableHeadRow from "../../../components/ui/table/TableHeadRow";
import TableContentRow from "../../../components/ui/table/TableContentRow";
import {useEffect, useState} from "react";
import {useLoaderData, useLocation, useNavigate} from "react-router-dom";
import updateState from "../../../lib/updateState";
import axios from "axios";
import getDataFromAPIResponse from "../../../lib/getDataFromAPIResponse";
import AddItem from "../../../components/form/AddItem";
import GreenAddButton from "../../../components/helper/GreenAddButton";
import Message from "../../../components/ui/Message";
import DateHelper from "../../../lib/DateHelper";
import throwPageMessage from "../../../lib/throwPageMessage";

export default function InvoiceClientsDetails() {

  const [message, setMessage] = useState(<></>)
  const [searchTermCheckObject, setSearchTermCheckObject] = useState("")

  const pathname = useLocation().pathname;
  const rawLoaderData = useLoaderData()
  let invoiceClientLoaderData = null;
  if(rawLoaderData && rawLoaderData.data)
    invoiceClientLoaderData = rawLoaderData.data

  const { state } = useLocation();
  let afterNewEntrySave = false;
  if(state && state.afterNewEntrySave) {
    afterNewEntrySave = true;
  }

  useEffect(() => {
    if(afterNewEntrySave)
      throwPageMessage(setMessage, "success", "Prüfobjekt erfolgreich gespeichert!")
  }, [afterNewEntrySave])

  const navigate = useNavigate()
  let initialState = {}
  if(!invoiceClientLoaderData) {
    initialState = {
      _id: "",
      name: "",
      address: {
        street: "",
        number: "",
        postcode: "",
        city: "",
        country: 0
      },
      contact: {
        gender: 0,
        preName: "",
        surName: "",
        email: ""
      },
      checkObjects: [

      ],
      invoices: []
    }
  }
  else {
    initialState = invoiceClientLoaderData
  }
  let newEntry = false;
  if(pathname.includes("/neu")) newEntry = true;

  const [invoiceClientData, setInvoiceClientData] = useState(initialState)
  const [filteredCheckObjects, setFilteredCheckObjects] = useState(initialState.checkObjects)

  const sortMethod = "asc"

  function sortCheckObjects(value, method) {
    const newCheckObjectsArray = [...invoiceClientData.checkObjects];
    const objKeys = Object.keys(newCheckObjectsArray[0])
    if(method === "desc") {
      newCheckObjectsArray.sort((a, b) => {
        if(a[objKeys[value]] < b[objKeys[value]]) return -1;
        else if(a[objKeys[value]] > b[objKeys[value]]) return 1;
        else return 0
      })
    }
    else {
      newCheckObjectsArray.sort((a, b) => {
        if(a[objKeys[value]] > b[objKeys[value]]) return -1;
        else if(a[objKeys[value]] < b[objKeys[value]]) return 1;
        else return 0
      })
    }
    updateState(invoiceClientData, setInvoiceClientData, ["checkObjects"], newCheckObjectsArray, true)
  }

  function onInvoiceClientSave() {
    if(newEntry) {
      axios
        .post(`${process.env.REACT_APP_API_URL}/invoiceClients/create`, invoiceClientData)
        .then(res => {
          const { err, data } = getDataFromAPIResponse(res)
          if(err) setMessage(<Message type={"error"} title={defaultStyles.messages.save.error} message={err} />)
          else return navigate(`../${data._id}`, {
            relative: "path",
            state: {
              afterNewEntrySave: true
            }
          })
        })
    }
    else {
      axios
        .patch(`${process.env.REACT_APP_API_URL}/invoiceClients/${invoiceClientData._id}`, invoiceClientData)
        .then(res => {
          const { err } = getDataFromAPIResponse(res)
          if(err) setMessage(<Message type={"error"} title={defaultStyles.messages.save.error} message={err} />)
          else setMessage(<Message type={"success"} title={defaultStyles.messages.save.success}/>)
          setTimeout(() => {
            setMessage(<></>)
          }, 3000)
        })
    }
  }

  function onInvoiceClientDelete() {
    if(window.confirm(`Möchten Sie den Rechnungsgempfänger ${invoiceClientData.name} wirklich löschen?`)) {
      axios
        .delete(`${process.env.REACT_APP_API_URL}/invoiceClients/${invoiceClientData._id}`)
        .then(res => {
          const { err } = getDataFromAPIResponse(res)
          if(err) setMessage(<Message type={"error"} title={defaultStyles.messages.save.error} message={res.data.err} />)
          else {
            alert("Der Rechnungsempfänger wurde erfolgreich gelöscht!")
            return navigate("/verwaltung/rechnungsempfaenger")
          }
        })
    }
  }
  function onCheckObjectSearch(newValue) {
    const afterFilter = invoiceClientData.checkObjects.filter(item => item.name.toLowerCase().includes(newValue.toLowerCase()))
    setFilteredCheckObjects(afterFilter)
    setSearchTermCheckObject(newValue)
  }

  return (
    <SubPage>
      <ActionBar>
        <ActionButton icon={"save"} title={"Speichern"} onClick={onInvoiceClientSave} />
        <ActionButton icon={"delete"} title={"Löschen"} onClick={onInvoiceClientDelete} />
      </ActionBar>
      { message }
      <Grid columns={"1fr 1fr"} gap={defaultStyles.element.padding.default}>
        <Grid columns={"1fr"} gap={defaultStyles.element.padding.default} maxContent>
          <Group name={"Stammdaten"} icon={"client"} gap={defaultStyles.element.padding.small}>
            <ControlledTextInput name={"Firma / Kunde"} value={invoiceClientData.name} onValueChange={(newValue) => updateState(invoiceClientData, setInvoiceClientData, ["name"], newValue, true)} />
            <Grid columns={"5fr 1fr"} gap={defaultStyles.element.padding.small}>
              <ControlledTextInput name={"Straße"} value={invoiceClientData.address.street} onValueChange={(newValue) => updateState(invoiceClientData, setInvoiceClientData, ["address", "street"], newValue, true)} />
              <ControlledTextInput name={"Hausnummer"} value={invoiceClientData.address.number} onValueChange={(newValue) => updateState(invoiceClientData, setInvoiceClientData, ["address", "number"], newValue, true)} />
            </Grid>
            <Grid columns={"1fr 5fr"} gap={defaultStyles.element.padding.small}>
              <ControlledTextInput name={"PLZ"} value={invoiceClientData.address.postcode} onValueChange={(newValue) => updateState(invoiceClientData, setInvoiceClientData, ["address", "postcode"], newValue, true)} />
              <ControlledTextInput name={"Ort"} value={invoiceClientData.address.city} onValueChange={(newValue) => updateState(invoiceClientData, setInvoiceClientData, ["address", "city"], newValue, true)} />
            </Grid>
            <ControlledSelect name={"Land"} value={invoiceClientData.address.country} onValueChange={(newValue) => updateState(invoiceClientData, setInvoiceClientData, ["address", "country"], newValue, true)}>
              <option value={1}>Deutschland</option>
              <option value={2}>Österreich</option>
            </ControlledSelect>
          </Group>
          <Group name={"Zentraler Ansprechpartner"} icon={"person"} gap={defaultStyles.element.padding.small}>
            <Grid columns={"1fr"} gap={defaultStyles.element.padding.small}>
              <Grid columns={"1fr 3fr 3fr"} gap={defaultStyles.element.padding.small}>
                <ControlledSelect name={"Anrede"} value={invoiceClientData.contact.gender} onValueChange={(newValue) => updateState(invoiceClientData, setInvoiceClientData, ["contact", "gender"], newValue, true)}>
                  <option value={1}>Herr</option>
                  <option value={2}>Frau</option>
                  <option value={3}>Keine Angabe</option>
                </ControlledSelect>
                <ControlledTextInput name={"Vorname"} value={invoiceClientData.contact.preName} onValueChange={(newValue) => updateState(invoiceClientData, setInvoiceClientData, ["contact", "preName"], newValue, true)} />
                <ControlledTextInput name={"Nachname"} value={invoiceClientData.contact.surName} onValueChange={(newValue) => updateState(invoiceClientData, setInvoiceClientData, ["contact", "surName"], newValue, true)} />
              </Grid>
              <ControlledTextInput name={"E-Mail-Adresse"} value={invoiceClientData.contact.email} onValueChange={(newValue) => updateState(invoiceClientData, setInvoiceClientData, ["contact", "email"], newValue, true)} />
            </Grid>
          </Group>
          {
            !newEntry && (
              <Group name={"Rechnungen"}>
                <Grid columns={"1fr"} gap={defaultStyles.element.padding.small}>
                  <Table>
                    <TableHeadRow columns={["Rechnung", "Datum"]} />
                    <tbody>
                    {
                      invoiceClientData.invoices.map((item, index) => {
                        const date = new DateHelper(item.date)
                        return <TableContentRow key={index} content={[item.invoiceId, date.getDateString()]} />
                      })
                    }
                    </tbody>
                  </Table>
                </Grid>
              </Group>
            )
          }
        </Grid>
        <Grid columns={"1fr"} gap={defaultStyles.element.padding.default} maxContent>
          {
            !newEntry && (
              <Group name={"Prüfobjekte"} maxContent>
                <GreenAddButton link={"neu"} right />
                <Grid columns={"1fr"} gap={defaultStyles.element.padding.default}>
                  <ControlledTextInput name={"Suche"} value={searchTermCheckObject} onValueChange={onCheckObjectSearch} />
                  <Table>
                    <TableHeadRow columns={["Name"]} onSortChange={sortCheckObjects} edit />
                    <tbody>
                    {
                      !invoiceClientData.checkObjects.length && <TableContentRow content={["Keine Prüfobjekte hinterlegt.", ""]} />
                    }
                    {
                      filteredCheckObjects.map((item, index) => {
                        return <TableContentRow key={index} content={[[item.name, {link: item._id}]]} edit={item._id} />
                      })
                    }
                    </tbody>
                  </Table>
                </Grid>
              </Group>
            )
          }
        </Grid>
      </Grid>
    </SubPage>
  )
}