import {
  useEffect,
  useState
} from "react";
import SubPage from "../../../components/ui/SubPage";
import ActionBar from "../../../components/ui/action_bar/ActionBar";
import ActionButton from "../../../components/ui/action_bar/ActionButton";
import Grid from "../../../components/helper/Grid";
import Group from "../../../components/ui/Group";
import defaultStyles from "../../../styles/defaultStyles";
import ControlledCheckbox from "../../../components/form/controlled/Checkbox";
import SubmitButton from "../../../components/form/SubmitButton";
import updateState from "../../../lib/updateState";
import {useLoaderData, useNavigate, useParams} from "react-router-dom";
import ControlledNumericInput from "../../../components/form/controlled/NumericInput";
import Table from "../../../components/ui/table/Table";
import TableHeadRow from "../../../components/ui/table/TableHeadRow";
import Message from "../../../components/ui/Message";
import throwPageMessage from "../../../lib/throwPageMessage";
import ControlledSelect from "../../../components/form/controlled/Select";
import axios from "axios";
import getDataFromAPIResponse from "../../../lib/getDataFromAPIResponse";
import ControlledTextInput from "../../../components/form/controlled/TextInput";
import download from "downloadjs"
import ControlledPriceInput from "../../../components/form/controlled/PriceInput";



export default function InvoiceDetails() {

  const { clientAndObjectData, products, categories, billingTypes } = useLoaderData()
  const params = useParams();
  const navigate = useNavigate();
  const { invoiceClientData, checkObjectData } = clientAndObjectData;
  console.log(checkObjectData)

  const [message, setMessage] = useState(<></>);

  const [invoiceSettings, setInvoiceSettings] = useState({
    useCheckObjectAddress: checkObjectData.invoiceSettings.useCheckObjectAddress,
    useContactInInvoiceAddress: checkObjectData.invoiceSettings.useContactInInvoiceAddress,
    nextMaintenanceDateInInvoice: checkObjectData.invoiceSettings.nextMaintenanceDateInInvoice,
    invoiceId: ""
  })

  const [invoiceProducts, setInvoiceProducts] = useState(products.map((product, index) => {
    return {
      id: product.id,
      billingType: product.billingType,
      name: product.name,
      price: product.price,
      amount: 0
    }
  }))

  const [discountAndCredit, setDiscountAndCredit] = useState({
    discount: {
      amount: 0,
      type: 0
    },
    credit: 0
  })

  const intermediateInvoicePrice = function () {
    const filtered = invoiceProducts.filter(item => item.amount > 0)
    let output = 0
    filtered.forEach(item => output += item.price * item.amount)
    return output.toFixed(2)
  }

  const calculateDiscount = function () {
    if(!discountAndCredit.discount.amount) return 0
    if(discountAndCredit.discount.type === 1) {
      return discountAndCredit.discount.amount
    }
    else if(discountAndCredit.discount.type === 2) {
      let currentSum = intermediateInvoicePrice();
      const currentSum_100 = currentSum/100
      currentSum = currentSum_100 * discountAndCredit.discount.amount
      return currentSum;
    }
    else {
      return 0
    }
  }

  const finalInvoicePrice = function () {
    const filtered = invoiceProducts.filter(item => item.amount > 0)
    let output = 0
    filtered.forEach(item => output += item.price * item.amount)
    return output.toFixed(2)
  }

  function updateBillingPosition(arrayKey, newValue) {
    const copy_invoiceProducts = invoiceProducts;
    copy_invoiceProducts[arrayKey].amount = newValue;
    setInvoiceProducts([...copy_invoiceProducts])
  }

  function onInvoiceSave(optionId) {
    const filteredBillingPositions = invoiceProducts.filter(item => item.amount > 0).map(item => {
      return {
        id: item.id,
        amount: item.amount
      }
    })
    if(!filteredBillingPositions.length) return throwPageMessage(setMessage, "error", "Es wurden keine Positionen für die Rechnungserstellung gewählt!");
    else if(!invoiceSettings.invoiceId) return throwPageMessage(setMessage, "error", "Es wurde keine Rechnungsnummer angegeben!")
    const submitSettings = {
      filteredBillingPositions,
      discountAndCredit,
      invoiceSettings,
      optionId
    }
    optionId === 2 && throwPageMessage(setMessage, "info", "Die Daten werden gespeichert und das PDF Dokument erstellt. Bitte aktualisieren Sie die Seite nicht!")
    axios
      .post(`${process.env.REACT_APP_API_URL}/invoices/${invoiceClientData._id}/${checkObjectData._id}`, submitSettings)
      .then(res => {
        const { err, data } = getDataFromAPIResponse(res)
        if(optionId !== 2) {
          console.log(data)
        }
        else {
          axios
            .get(`${process.env.REACT_APP_API_URL}/invoices/download/${data}`, { responseType: "blob" })
            .then(res => {
              const file = new Blob(
                [res.data],
                { type: 'application/pdf',
                  name: "Test" }
              )
              //const fileUrl = URL.createObjectURL(file)
              let currentDate = new Date()
              const dateString = `${currentDate.getFullYear()}_${currentDate.getMonth()+1}_${currentDate.getDate()}`
              download(file, `${dateString}_${invoiceSettings.invoiceId}.pdf`, 'application/pdf')
              return throwPageMessage(setMessage, "success", "Die Rechnung wurde gespeichert und das Dokument heruntergeladen.")
            })
        }
      })
  }

  let maxDiscount = 0
  if(discountAndCredit.discount.type === 1) {
    maxDiscount = intermediateInvoicePrice()
  }
  else if(discountAndCredit.discount.type === 2) {
    maxDiscount = 100
  }
  else maxDiscount = 0

  let contactJSX = <></>
  if(invoiceSettings.useContactInInvoiceAddress) {
    function getSalutation(genderId) {
      if(genderId === 1) return " Herrn "
      else if(genderId === 2) return " Frau "
      else return ""
    }
    if(checkObjectData.contact.useMainContact) {
      contactJSX = <>z.Hd.{getSalutation(invoiceClientData.contact.gender)}{invoiceClientData.contact.preName} {invoiceClientData.contact.surName}<br /></>
    }
    else {
      contactJSX = <>z.Hd.{getSalutation(checkObjectData.contact.gender)}{checkObjectData.contact.preName} {checkObjectData.contact.surName}<br /></>
    }
  }

  let addressJSX = <></>
  if(!invoiceSettings.useCheckObjectAddress) {
    addressJSX = (
      <p>
        {invoiceClientData.name}<br />
        {contactJSX}
        {invoiceClientData.address.street} {invoiceClientData.address.number}<br />
        {invoiceClientData.address.postcode} {invoiceClientData.address.city}<br />
        Deutschland
      </p>
    )
  }
  else {
    addressJSX = (
      <p>
        {checkObjectData.name}<br />
        {contactJSX}
        {checkObjectData.address.street} {checkObjectData.address.number}<br />
        {checkObjectData.address.postcode} {checkObjectData.address.city}<br />
        Deutschland
      </p>
    )
  }

  return (
    <SubPage>
      <ActionBar>
        <ActionButton icon={"back"} title={"Zurück zum Prüfobjekt"} onClick={() => navigate(`/verwaltung/rechnungsempfaenger/${params.invoiceClientId}/${params.checkObjectId}`)} />
      </ActionBar>
      {message}
      <Grid columns={"1fr 2fr"} gap={defaultStyles.element.padding.default}>
        <Group name={"Rechnungseinstellungen"} maxContent>
          <Grid columns={"1fr"} gap={defaultStyles.element.padding.small}>
            <ControlledTextInput name={"Rechnungsnummer"} value={invoiceSettings.invoiceId} onValueChange={newValue => updateState(invoiceSettings, setInvoiceSettings, ["invoiceId"], newValue, true)} />
            <ControlledCheckbox value={invoiceSettings.useCheckObjectAddress} onValueChange={newValue => updateState(invoiceSettings, setInvoiceSettings, ["useCheckObjectAddress"], newValue, true)}>Rechnungsanschrift des Prüfobjekts verwenden</ControlledCheckbox>
            <ControlledCheckbox value={invoiceSettings.useContactInInvoiceAddress} onValueChange={newValue => updateState(invoiceSettings, setInvoiceSettings, ["useContactInInvoiceAddress"], newValue, true)}>Ansprechpartner in Rechnungsanschrift verwenden</ControlledCheckbox>
            <ControlledCheckbox value={invoiceSettings.nextMaintenanceDateInInvoice} onValueChange={newValue => updateState(invoiceSettings, setInvoiceSettings, ["nextMaintenanceDateInInvoice"], newValue, true)}>Nächsten Wartungstermin auf Rechnung ausweisen</ControlledCheckbox>
          </Grid>
        </Group>
        <Grid columns={"1fr"} gap={defaultStyles.element.padding.default}>
          <Grid columns={"1fr 1fr"} gap={defaultStyles.element.padding.default}>
            <Group name={"Anschrift"}>
              { addressJSX }
            </Group>
            <Group name={"Nächster Wartungstermin"}>
              <p>Wird nicht auf Rechnung ausgewiesen.</p>
            </Group>
          </Grid>
          <Group name={"Rechnungspositionen"}>
            <Grid columns={"1fr"} gap={defaultStyles.element.padding.small}>
              <Table>
                <TableHeadRow columns={["Produktname", "Preis", "Einheit", "Anzahl"]} />
                <tbody>
                  {
                    invoiceProducts.map((item, index) => {
                      const billingType = billingTypes.find(bt => bt.id === item.billingType)
                      return (
                        <tr key={index}>
                          <td>{item.name}</td>
                          <td>{item.price.toFixed(2)}€</td>
                          <td>{billingType.name}</td>
                          <td style={{ width: "10rem" }}><ControlledNumericInput value={item.amount} onValueChange={newValue => updateBillingPosition(index, newValue)} min={0} max={999} /></td>
                        </tr>
                      )
                    })
                  }
                  <tr style={{ height: "3rem", fontWeight: 600 }}>
                    <td></td>
                    <td></td>
                    <td>Zwischenbetrag:</td>
                    <td>{intermediateInvoicePrice()}€</td>
                  </tr>
                  <tr>
                    <td style={{ color: defaultStyles.color.green.dark, fontWeight: "600" }}>Rabatt</td>
                    <td style={{ width: "12.5rem" }}><ControlledNumericInput min={0} max={maxDiscount} value={discountAndCredit.discount.amount} onValueChange={newValue => updateState(discountAndCredit, setDiscountAndCredit, ["discount", "amount"], newValue, true)} /></td>
                    <td>
                      <ControlledSelect value={discountAndCredit.discount.type} onValueChange={newValue => updateState(discountAndCredit, setDiscountAndCredit, ["discount", "type"], newValue, true)}>
                        <option value={0}>Keiner</option>
                        <option value={1}>pauschal (in €)</option>
                        <option value={2}>prozentual (in %)</option>
                      </ControlledSelect>
                    </td>
                    <td>
                      { (calculateDiscount() * -1).toFixed(2) +"€" }
                    </td>
                  </tr>
                  <tr>
                    <td style={{ color: defaultStyles.color.green.dark, fontWeight: "600" }}>Gutschrift</td>
                    <td><ControlledPriceInput min={0} value={discountAndCredit.credit} onValueChange={newValue => updateState(discountAndCredit, setDiscountAndCredit, ["credit"], newValue, true)} /></td>
                    <td>pauschal</td>
                    <td>{ (discountAndCredit.credit * -1).toFixed(2) }€</td>
                  </tr>
                  <tr style={{ height: "3rem", fontWeight: 600 }}>
                    <td></td>
                    <td></td>
                    <td>Gesamtbetrag:</td>
                    <td>{(finalInvoicePrice() - calculateDiscount() - discountAndCredit.credit).toFixed(2)}€</td>
                  </tr>
                </tbody>
              </Table>
              <Grid columns={"1fr 1fr 1fr"} gap={defaultStyles.element.padding.small}>
                <SubmitButton disabled onClick={() => onInvoiceSave(1)}>Rechnung speichern</SubmitButton>
                <SubmitButton onClick={() => onInvoiceSave(2)}>Rechnung speichern und herunterladen</SubmitButton>
                <SubmitButton disabled>Rechnung speichern und per E-Mail versenden</SubmitButton>
              </Grid>
            </Grid>
          </Group>
        </Grid>
      </Grid>
    </SubPage>
  );
}