//External
import { useEffect } from "react";
import {
  NavLink,
  Outlet,
  useLocation
} from "react-router-dom"
import styled from "styled-components";
import {
  useNavigate,
  useRouteLoaderData
} from "react-router-dom";
import axios from "axios";
import Cookies from "universal-cookie";

//Functions & Vars
import defaultStyles from "../../styles/defaultStyles";

//Components
import Icon from "../helper/Icon";
import OwnProfileTile from "../ui/OwnProfileTile";


export default function Layout() {
  const navigate = useNavigate()
  const location = useLocation().pathname
  const userData = useRouteLoaderData("root")
  useEffect(() => {
    (async () => {
      if(location === "/") return navigate("/dashboard", { replace: "ture" })
      if(location === "/admin") return navigate("/admin/benutzerverwaltung", { replace: "true" })
      if(location === "/verwaltung") return navigate("/verwaltung/rechnungsempfaenger", { replace: "true" })
      const cookie = new Cookies()
      axios.interceptors.request
        .use((config) => {
          if(!cookie.get("appToken")) return navigate("/login")
          const token = cookie.get("appToken")
          config.headers['Authorization'] = `Bearer ${token}`;
          return config
        },
        () => {
          axios.interceptors.request.clear();
          cookie.remove("appToken", { path: "/" })
          return navigate("/login", { replace: true })
        },
        {
          runWhen: (config) => config.url !== `${process.env.REACT_APP_API_URL}/login`
        }
      )


      axios.interceptors.response.use((res) => {
        if(!res.data.authError) return res
        axios.interceptors.request.clear();
        cookie.remove("appToken", { path: "/" })
        return navigate("/login")
      })
    })();
  }, [location, navigate])
  if(!userData) return navigate("/login")
  function onLogoutClick(e) {
    e.preventDefault()
    if(window.confirm("Möchten Sie sich wirklich abmelden?")) {
      const cookie = new Cookies()
      cookie.remove("appToken", { path: "/" })
      return navigate("/login")
    }
  }
  return (
    <StyledLayout>
      <header>
        <OwnProfileTile initials={userData.prename[0] + userData.surname[0]} />
        <button type={"button"} onClick={onLogoutClick}>
          <Icon icon={"logout"} size={"2rem"} color={"#000"} />
        </button>
      </header>
      <nav>
        <div className={"logo"}>
          <span>bav<br/>aria</span>
        </div>
        <ul>
          <li>
            <NavLink
              to={"dashboard"}
              title={"Dashboard"}
              className={({ isActive }) =>
                isActive ? "active" : null
              }
            >
              <Icon icon={"dashboard"} size={"2rem"} color={defaultStyles.color.grey.light} />
            </NavLink>
          </li>
          <li>
            <NavLink
              to={"verwaltung"}
              title={"Verwaltung"}
              className={({ isActive }) =>
                isActive ? "active" : null
              }
            >
              <Icon icon={"fireplace"} size={"2rem"} color={defaultStyles.color.grey.light} />
            </NavLink>
          </li>
          {
            userData.admin && (
              <>
                <li>
                  <NavLink
                    to={"admin"}
                    title={"Administration"}
                    className={({ isActive }) =>
                      isActive ? "active" : null
                    }
                  >
                    <Icon icon={"admin_panel"} size={"2rem"} color={defaultStyles.color.grey.light} />
                  </NavLink>
                </li>
              </>
            )
          }
        </ul>
      </nav>
      <main>
        <Outlet />
      </main>
    </StyledLayout>
  )
}

const StyledLayout = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: ${defaultStyles.layout.nav.width} 1fr;
  grid-template-rows: ${defaultStyles.layout.header.height} 1fr;
  grid-template-areas: "nav header" "nav main";
  overflow: hidden;
  > header {
    grid-area: header;
    display: flex;
    z-index: 2;
    justify-content: end;
    align-items: center;
    gap: 1rem;
    padding: 0 1.25rem;
    box-shadow: .125rem 0 .125rem 0 rgba(0,0,0,0.5);
    > button {
      background-color: transparent;
      border: 0;
      cursor: pointer;
      padding: 0;
    }
  }
  > nav {
    grid-area: nav;
    z-index: 2;
    box-shadow: 0 0 .125rem 0 rgba(0,0,0,0.5);
    > div.logo {
      width: 100%;
      height: ${defaultStyles.layout.header.height};
      display: flex;
      justify-content: center;
      align-items: center;
      > span {
        text-align: center;
        font-weight: 600;
        font-size: 1.25rem;
        line-height: 1.2rem;
      }
    }
    > ul {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: .5rem;
      padding: 0 1.25rem;
      > li {
        height: calc(${defaultStyles.layout.nav.width} - 2.5rem);
        list-style: none;
        > a {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          text-decoration: none;
          border-radius: ${defaultStyles.element.border.radius.small};
          > i {
            transition: ${defaultStyles.element.transition};
          }
          > span.nol {
            font-size: 1.25rem;
            font-weight: 600;
            color: ${defaultStyles.color.grey.light};
          }
          &:hover {
            > i {
              background-color: ${defaultStyles.color.theme.default};
            }
            > span.nol {
              color: ${defaultStyles.color.theme.default};
            }
          }
          &.active {
            background-color: ${defaultStyles.color.theme.default};
            box-shadow: ${defaultStyles.element.boxShadow};
            > i {
              background-color: #fff !important;
            }
            > span.nol {
              color: #fff;
            }
          }
        }
      }
    }
  }
  > main {
    grid-area: main;
    background-color: #f4f6f8;
    //padding: ${defaultStyles.element.padding.default};
    p {
      margin: 0;
    }
  }
`

