export default class DateHelper{
  constructor(date) {
    this.date = date
  }
  getDateString() {
    const date = new Date(this.date)
    const day = date.getDate() > 9 ? date.getDate() : "0" + date.getDate()
    const month = date.getMonth()+1 > 9 ? date.getMonth()+1 : "0" + date.getMonth()+1
    const year = date.getFullYear();
    return `${day}.${month}.${year}`
  }
}