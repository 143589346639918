//External
import styled, {css} from "styled-components";

//Functions & Vars
import defaultStyles from "../../../styles/defaultStyles";

//Components


export default function DeleteActionIcon(props) {

  const {
    size,
    color,
    onClick,
    position
  } = props;

  function onClickHandler(e) {
    e.preventDefault()
    onClick()
  }

  return (
    <StyledIcon
      icon={"delete"}
      size={size}
      color={color}
      onClick={onClickHandler}
      position={position}
    />
  );
}

const StyledIcon = styled.i`
  display: inline-block;
  width: ${props => props.size};
  height: ${props => props.size};
  min-width: ${props => props.size};
  min-height: ${props => props.size};
  mask: url(/icons/${props => props.icon}.svg) no-repeat;
  mask-size: ${props => props.size};
  background-color: ${props => props.color};
  cursor: pointer;
  ${props => {
    if(props.position === "absolute") {
      return css`
        position: absolute;
        right: 1rem;
        top: 1rem;
      `
    }
  }}
  &:hover {
    background-color: ${defaultStyles.color.red.dark};
  }
`