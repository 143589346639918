import SubPage from "../../../components/ui/SubPage";
import ActionBar from "../../../components/ui/action_bar/ActionBar";
import ActionButton from "../../../components/ui/action_bar/ActionButton";
import Group from "../../../components/ui/Group";
import Table from "../../../components/ui/table/Table";
import TableHeadRow from "../../../components/ui/table/TableHeadRow";
import TableContentRow from "../../../components/ui/table/TableContentRow";
import {useLoaderData, useNavigate} from "react-router-dom";

export default function InvoiceClientsDashboard() {

  const invoiceClients = useLoaderData().data
  const navigate = useNavigate()

  return (
    <SubPage>
      <ActionBar>
        <ActionButton icon={"add"} title={"Rechnungsempfänger hinzufügen"} onClick={() => navigate("neu")} />
      </ActionBar>
      <Group icon={"client"} name={"Rechnungsempfänger"}>
        <Table>
          <TableHeadRow columns={[
            "Rechnungsempfänger",
            "Anzahl Prüfobjekte"
          ]} edit />
          <tbody>
          {
            invoiceClients.map((client, index) => {
              let checkObjectsCount = 0;
              if(client.checkObjects) checkObjectsCount = client.checkObjects.length
              return <TableContentRow key={index} content={[[client.name, { link: `${client._id}` }], checkObjectsCount]} edit={`${client.id}`} />
            })
          }
          </tbody>
        </Table>
      </Group>
    </SubPage>
  )
}