import { createBrowserRouter } from "react-router-dom";

import Layout from "../components/layout/Layout";
import Login from "../pages/login/Login";
import MyProfile from "../pages/MyProfile";
import rootLoader from "./loader/rootLoader";
import AdminLayout from "../pages/admin/AdminLayout";
import UserManagement from "../pages/admin/UserManagement";
import userManagementLoader from "./loader/admin/userManagementLoader";
import UserDetails from "../pages/admin/UserDetails";
import userDetailsLoader from "./loader/admin/userDetailsLoader";
import AdminSettings from "../pages/admin/AdminSettings";
import UserAdd from "../pages/admin/UserAdd";
import Dashboard from "../pages/Dashboard";
import VerwaltungLayout from "../pages/verwaltung/VerwaltungLayout";
import InvoiceClientsDashboard from "../pages/verwaltung/invoice_client/InvoiceClientsDashboard";
import InvoiceClientsDetails from "../pages/verwaltung/invoice_client/InvoiceClientsDetails";
import invoiceClientsLoader from "./loader/invoiceClients/invoiceClientsLoader";
import invoiceClientDetailsLoader from "./loader/invoiceClients/invoiceClientDetailsLoader";
import CheckObjectDetails from "../pages/verwaltung/invoice_client/CheckObjectDetails";
import ProductsPrices from "../pages/verwaltung/products_and_prices/ProductsPrices";
import productsAndPricesLoader from "./loader/invoiceClients/productsAndPricesLoader";
import checkObjectDetailsLoader from "./loader/invoiceClients/checkObjectDetailsLoader";
import InvoiceDetails from "../pages/verwaltung/invoice_client/InvoiceDetails";
import invoicesLoader from "./loader/invoiceClients/invoicesLoader";
import ErrorElement from "../pages/ErrorElement";
import NotFound from "../pages/NotFound";

const router = createBrowserRouter([
  {
    path: "/login",
    element: <Login />
  },
  {
    path: "/",
    element: <Layout />,
    id: "root",
    loader: rootLoader,
    children: [
      {
        path: "meinprofil",
        element: <MyProfile />
      },
      {
        path: "dashboard",
        element: <Dashboard />
      },
      {
        path: "verwaltung",
        element: <VerwaltungLayout />,
        children: [
          {
            path: "rechnungsempfaenger",
            element: <InvoiceClientsDashboard />,
            loader: invoiceClientsLoader
          },
          {
            path: "rechnungsempfaenger/neu",
            element: <InvoiceClientsDetails />,
            loader:  () => { return null }
          },
          {
            path: "rechnungsempfaenger/:invoiceClientId",
            element: <InvoiceClientsDetails />,
            loader: invoiceClientDetailsLoader
          },
          {
            path: "rechnungsempfaenger/:invoiceClientId/:checkObjectId",
            element: <CheckObjectDetails />,
            loader: checkObjectDetailsLoader,
            errorElement: <ErrorElement />
          },
          {
            path: "rechnungsempfaenger/:invoiceClientId/:checkObjectId/rechnung/neu",
            element: <InvoiceDetails />,
            loader: invoicesLoader
          },
          {
            path: "produkte_und_preise",
            element: <ProductsPrices />,
            loader: productsAndPricesLoader
          }
        ]
      },
      {
        path: "admin",
        element: <AdminLayout />,
        children: [
          {
            path: "benutzerverwaltung",
            element: <UserManagement />,
            loader: userManagementLoader
          },
          {
            path: "benutzerverwaltung/erstellen",
            element: <UserAdd />
          },
          {
            path: "benutzerverwaltung/:userId",
            element: <UserDetails />,
            loader: userDetailsLoader
          },
          {
            path: "einstellungen",
            element: <AdminSettings />
          }
        ]
      },
      {
        path: "*",
        element: <NotFound />
      }
    ]
  }
])

export default router