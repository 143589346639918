//External
import {
  useEffect
} from "react";
import {
  Outlet,
  useRouteLoaderData,
  useNavigate
} from "react-router-dom";

//Functions & Vars


//Components
import PageNavBar from "../../components/ui/page_navigation/PageNavBar";
import NavTile from "../../components/ui/page_navigation/NavTile";

export default function AdminLayout(props) {
  const navigate = useNavigate()
  const userData = useRouteLoaderData("root")
  useEffect(() => {
    if(!userData.admin) return navigate("/")
  })
  return (
    <>
      <PageNavBar name={"Administration"}>
        <NavTile to={"benutzerverwaltung"}>Benutzerverwaltung</NavTile>
        <NavTile to={"einstellungen"}>Einstellungen</NavTile>
      </PageNavBar>
      <Outlet />
    </>
  )
}