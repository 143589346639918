import Cookies from "universal-cookie";
import axios from "axios";
import {redirect} from "react-router-dom";

const rootLoader = async function () {
  const cookie = new Cookies()
  if(!cookie.get("appToken")) return redirect("/login");
  const token = cookie.get("appToken")
  axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  return await axios
    .post(`${process.env.REACT_APP_API_URL}/auth`, {
      token
    })
    .then(res => {
      if(res.data.err) return redirect("/login")
      const token = res.data.data.token
      cookie.set("appToken", JSON.stringify(token), {
        path: "/",
        sameSite: true
      })
      return res.data.data.userData
    })
    .catch(err => {
      return redirect("/login");
    })
}

export default rootLoader