//External
import styled from "styled-components";

//Functions & Vars
import defaultStyle from "../../../styles/defaultStyles";

//Components
import generateUniqueElementId from "../../../lib/generateUniqueElementId";

export default function ControlledCheckbox(props) {

  const {
    title,
    value,
    children,
    onValueChange
  } = props;

  function innerOnChange(e) {
    onValueChange(!!e.target.checked)
  }

  const checkboxId = generateUniqueElementId()
  return (
    <StyledCheckboxWrapper>
      <StyledCheckbox title={title}>
        <input id={checkboxId} type="checkbox" checked={value} onChange={innerOnChange} />
        <div className="toggler-slider">
          <div className="toggler-knob"></div>
        </div>
      </StyledCheckbox>
      <label title={title} htmlFor={checkboxId} className={"checkbox-textlabel"}>{ children }</label>
    </StyledCheckboxWrapper>
  );
}

const StyledCheckboxWrapper = styled.div`
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-gap: 1rem;
  align-items: center;
  position: relative;
  label.checkbox-textlabel {
    width: max-content;
    font-size: 1rem;
    align-self: end;
    margin-bottom: .4rem;
    cursor: pointer;
    user-select: none;
  }
`

const StyledCheckbox = styled.label`
  display: block;
  width: 4rem;
  height: 2rem;
  cursor: pointer;
  position: relative;
  align-self: end;
  margin: 0;
  &:focus {
    box-shadow: 0 0 0 2px ${defaultStyle.color.theme.alpha};
  }
  .toggler-slider {
    background-color: #ccc;
    position: absolute;
    border-radius: calc(${defaultStyle.element.border.radius.small} / 2);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -webkit-transition: all 300ms ease;
    transition: all 300ms ease;
  }
  input[type="checkbox"]:checked + .toggler-slider {
    background-color: ${defaultStyle.color.theme.default};
  }
  .toggler-knob {
    position: absolute;
    -webkit-transition: all 300ms ease;
    transition: all 300ms ease;
    width: calc(2rem - 8px);
    height: calc(2rem - 8px);
    border-radius: calc(${defaultStyle.element.borderRadius} / 2);
    left: 4px;
    top: 4px;
    background-color: #fff;
  }
  input[type="checkbox"]:checked + .toggler-slider .toggler-knob {
    left: calc(100% - 5px);
    transform: translateX(-100%);
  }
`