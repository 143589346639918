import Message from "../components/ui/Message";
import defaultStyles from "../styles/defaultStyles";

export default function throwPageMessage(stateSetterFunction, type, message, messageList = false) {
  const mainElement = document.getElementById('subpage')
  mainElement.scroll({
    top: 0,
    behavior: 'smooth'
  })
  let title
  if(type === "success") {
    title = defaultStyles.messages.save.success
    setTimeout(() => stateSetterFunction(<></>), 3000)
  }
  else if(type === "info") {
    title = "Information"
  }
  else {
    title = defaultStyles.messages.save.error
  }
  return stateSetterFunction(<Message type={type} title={title} message={message} />)
}