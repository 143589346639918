const convertToDate = function (dateInput, shortVersion = false) {
  const unixTime = Date.parse(dateInput)
  const date = new Date(unixTime)
  let dayName = ""
  switch (date.getUTCDay()) {
    case 1:
      dayName = "Montag"
      break
    case 2:
      dayName = "Dienstag"
      break
    case 3:
      dayName = "Mittwoch"
      break
    case 4:
      dayName = "Donnerstag"
      break
    case 5:
      dayName = "Freitag"
      break
    case 6:
      dayName = "Samstag"
      break
    case 0:
      dayName = "Sonntag"
      break
    default:
      dayName = "FEHLER"
      break
  }
  let month = date.getUTCMonth()+1
  let day = date.getUTCDate()
  if(month < 10) month = "0" + month
  if(day < 10) day = "0" + day
  if(shortVersion) return `${day}.${month}.${date.getUTCFullYear()}`
  else return `${dayName}, ${day}.${month}.${date.getUTCFullYear()}`
}

const convertToDateTime = function (dateInput) {
  const unixTime = Date.parse(dateInput)
  const date = new Date(unixTime)
  let dayName = ""
  switch (date.getUTCDay()) {
    case 1:
      dayName = "Montag"
      break
    case 2:
      dayName = "Dienstag"
      break
    case 3:
      dayName = "Mittwoch"
      break
    case 4:
      dayName = "Donnerstag"
      break
    case 5:
      dayName = "Freitag"
      break
    case 6:
      dayName = "Samstag"
      break
    case 0:
      dayName = "Sonntag"
      break
    default:
      dayName = "FEHLER"
      break
  }
  let month = date.getUTCMonth()+1
  let day = date.getUTCDate()
  let hours = date.getUTCHours()
  let minutes = date.getUTCMinutes()
  if(month < 10) month = "0" + month
  if(day < 10) day = "0" + day
  if(hours < 10) hours = "0" + hours
  if(minutes < 10) minutes = "0" + minutes
  return `${dayName}, ${day}.${month}.${date.getUTCFullYear()}, ${hours}:${minutes} Uhr`
}

const convertToDateInput = function (dateInput) {
  if(!dateInput) return ""
  const unixTime = Date.parse(dateInput)
  const date = new Date(unixTime)
  let month = date.getUTCMonth()+1
  let day = date.getUTCDate()
  if(month < 10) month = "0" + month
  if(day < 10) day = "0" + day
  return `${date.getUTCFullYear()}-${month}-${day}`
}

const convertToDateTimeInput = function (dateInput) {
  let dateVar = dateInput
  if(dateInput && dateInput.length === 16) dateVar = dateInput + ":00.000Z"
  if(!dateVar) return null
  const unixTime = Date.parse(dateVar)
  let date = new Date(unixTime)
  //const timeOffset = date.getTimezoneOffset() * 60000
  //date = new Date(date.getTime() + timeOffset)
  let month = date.getUTCMonth()+1
  let day = date.getUTCDate()
  let hours = date.getUTCHours()
  let minutes = date.getUTCMinutes()
  if(month < 10) month = "0" + month
  if(day < 10) day = "0" + day
  if(hours < 10) hours = "0" + hours
  if(minutes < 10) minutes = "0" + minutes
  return `${date.getUTCFullYear()}-${month}-${day}T${hours}:${minutes}`
}

const convertToDateObject = function (dateInput) {
  if(!dateInput) return null
  const unixTime = Date.parse(dateInput)
  return new Date(unixTime)
}

const getTimeUntilDate = function (dateInput) {
  const unixTime = Date.parse(dateInput)/1000
  const dateNow = Math.floor(Date.now() / 1000)
  const unixTimeLeft = unixTime - dateNow
  let monthsLeft = Math.floor(unixTimeLeft/60/60/24/30.5)
  let daysLeft = Math.floor((unixTimeLeft/60/60/24/30.5) * monthsLeft)
  return monthsLeft + " " + daysLeft
}

const validateDateInput = function (dateInput) {
  if(dateInput !== "" && dateInput) {
    let output = dateInput.split(".")[0].replace("T", " ")
    if(output.length === 16) return output + ":00"
    else return output
  }
  else return null
}

export {
  convertToDate,
  convertToDateTime,
  convertToDateInput,
  convertToDateObject,
  getTimeUntilDate,
  convertToDateTimeInput,
  validateDateInput
}