//External
import { Link } from "react-router-dom";
import styled from "styled-components";

//Functions & Vars
import defaultStyles from "../../styles/defaultStyles";

//Components


export default function OwnProfileTile(props) {

  const {
    initials
  } = props;

  return (
    <StyledUserTile>
      <Link to={"/meinprofil"}>
        <span>{ initials }</span>
      </Link>
    </StyledUserTile>
  );
}

const StyledUserTile = styled.div `
  width: 3.5rem;
  height: 3.5rem;
  min-height: 3.5rem;
  min-width: 3.5rem;
  > a {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: ${defaultStyles.color.theme.default};
    color: #fff;
    font-size: 1.25rem; 
    text-decoration: none;
    transition: ${defaultStyles.element.transition};
    &:hover {
      background-color: ${defaultStyles.color.theme.hover};
      box-shadow: ${defaultStyles.element.boxShadow};
    }
  }
`