//External


//Functions & Vars
import StyledInput from "../../../styles/StyledInput"
import defaultStyles from "../../../styles/defaultStyles";
import generateUniqueElementId from "../../../lib/generateUniqueElementId";

//Components
import Icon from "../../helper/Icon";
import styled, {css} from "styled-components";


export default function ControlledPriceInput(props) {
  const {
    canCopy,
    name,
    value,
    disabled,
    onEnterKey,
    notice
  } = props;

  let full = Math.floor(value)
  let decimal = parseFloat(value).toString().split(".")[1] === undefined ? 0 : parseFloat(value).toString().split(".")[1]

  console.log(decimal)

  const elementId = generateUniqueElementId()

  function innerOnValueChange(e, type) {
    e.preventDefault()
    const allowedChars = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"]
    const newValue = e.target.value;
    let filteredValue = ""
    for(let i=0; i<newValue.length; i++) {
      if(allowedChars.includes(newValue[i])) filteredValue += newValue[i];
    }

    console.log(filteredValue)
    let newPushValue = 0;
    if(type === 1) {
      newPushValue = Math.floor(parseInt(filteredValue)).toFixed(2)
      newPushValue += decimal
    }
    else {
      if(filteredValue.length > 2) return 0;
      newPushValue = full
      newPushValue += parseFloat("00." + filteredValue)
    }
    return props.onValueChange(parseFloat(newPushValue).toFixed(2))
  }

  function innerOnKeyDown(e) {
    if(e.key === "Enter") return props.onEnterKey(e)
  }

  function onCopyContent() {
    navigator.clipboard
      .writeText(value)
      .then(() => {
        return alert("Link kopiert")
      })
  }

  const noticeStyle = {
    display: "grid",
    gridTemplateColumns: "max-content 1fr",
    gridGap: ".25rem",
    fontSize: ".8rem",
    marginTop: ".5rem"
  }

  if(onEnterKey) {
    return (
      <StyledInput canCopy={canCopy}>
        <label
          htmlFor={elementId}
        >{ name }</label>
        <input
          id={elementId}
          type={"text"}
          value={value}
          onKeyDown={innerOnKeyDown}
          onChange={innerOnValueChange}
          placeholder={name}
          disabled={disabled}
        />
        {
          canCopy && (
            <span onClick={onCopyContent}><Icon icon={"link"} size={"1.25rem"} color={"#000"} /></span>
          )
        }
      </StyledInput>
    );
  }
  else {
    return (
      <StyledPriceWrapper name={name}>
        <label
          htmlFor={elementId}
        >{ name }</label>
        <StyledInput canCopy={canCopy}>
          <input
            id={elementId}
            type={"text"}
            value={full}
            onChange={(e) => innerOnValueChange(e, 1)}
            placeholder={name}
            disabled={disabled}
          />
        </StyledInput>
        <span>,</span>
        <StyledInput canCopy={canCopy}>
          <input
            id={elementId}
            type={"text"}
            value={decimal}
            onChange={(e) => innerOnValueChange(e, 2)}
            placeholder={name}
            disabled={disabled}
          />
        </StyledInput>
      </StyledPriceWrapper>
    );
  }
}

const StyledPriceWrapper = styled.div`
  display: grid;
  grid-template-columns: max-content max-content 1fr;
  > label {
    grid-column: 1/4;
  }
  > span {
    display: flex;
    height: 100%;
    align-items: end;
    font-size: 1.25rem;
    border-top: ${defaultStyles.element.border.default};
    border-bottom: ${defaultStyles.element.border.default};
  }
  > div {
    width: min-content;
    &:first-of-type {
      min-width: 2rem;
      input {
        width: max-content;
        border-right: 0;
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
        //text-align: right;
      }
    }
    &:last-of-type {
      input {
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
        border-left: 0;
      }
    }
  } 
  ${props => {
    if(!props.name) return css`
      > label {
        display: none;
      }
    `;
  }}
`;