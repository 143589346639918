//External
import styled, {css} from "styled-components";
import { Link } from "react-router-dom";

//Functions & Vars
import defaultStyles from "../../styles/defaultStyles";

//Components
import Icon from "./Icon";

export default function GreenAddButton(props) {

  const {
    link,
    onClick,
    right
  } = props;

  function onClickHandler(e) {
    e.preventDefault()
    onClick()
  }

  if(link) {
    return (
      <StyledGreenAddButton right={right}>
        <Link to={link}>
          <Icon icon={"add"} size={"1.5rem"} color={"#fff"} />
        </Link>
      </StyledGreenAddButton>
    );
  }
  else {
    return (
      <StyledGreenAddButton onClick={onClickHandler}>
        <Icon icon={"add"} size={"1.5rem"} color={"#fff"} />
      </StyledGreenAddButton>
    );
  }
}

const StyledGreenAddButton = styled.button `
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
  border: 0;
  border-radius: 50%;
  background-color: ${defaultStyles.color.green.dark};
  cursor: pointer;
  ${props => {
    if(props.right) return css `
      position: absolute;
      right: ${defaultStyles.element.padding.small};
      top: ${defaultStyles.element.padding.default};
    `
  }}
  > a {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &:hover {
    background-color: ${defaultStyles.color.green.light};
  }
`