export default function onSaveKeyPressed (event, callback) {
  const keyDown = (event) => {
    if (event.key === "s" && event.ctrlKey) {
      event.preventDefault();
      callback()
    }
  };
  document.addEventListener("keydown", keyDown);
  return () => {
    document.removeEventListener("keydown", keyDown);
  };
}