//External
import {
  useEffect
} from "react";
import {
  Outlet,
  useRouteLoaderData,
  useNavigate
} from "react-router-dom";

//Functions & Vars


//Components
import PageNavBar from "../../components/ui/page_navigation/PageNavBar";
import NavTile from "../../components/ui/page_navigation/NavTile";

export default function VerwaltungLayout(props) {
  return (
    <>
      <PageNavBar name={"Verwaltung"}>
        <NavTile to={"rechnungsempfaenger"}>Rechnungsempfänger & Standorte</NavTile>
        <NavTile disabled to={"pruefobjekte"}>Prüfobjekte & Wartungstermine</NavTile>
        <NavTile to={"produkte_und_preise"}>Produkte & Preise</NavTile>
        <NavTile disabled to={"rechnungen"}>Rechnungen</NavTile>
      </PageNavBar>
      <Outlet />
    </>
  )
}