//External
import styled, { css } from "styled-components";

//Functions & Vars
import defaultStyles from "../../styles/defaultStyles";

//Components
import Heading from "./Heading";
import Icon from "../helper/Icon";
import GreenAddButton from "../helper/GreenAddButton";

export default function Group(props) {

  const {
    icon,
    pattern,
    maxContent,
    name,
    addButtonLink,
    children
  } = props;

  let internalIcon = <></>
  if(icon) internalIcon = <Icon icon={icon} size={"1.5rem"} color={"#000"} />
  return (
    <StyledGroup pattern={pattern} maxContent={maxContent} addButtonLink={addButtonLink}>
      <Heading type={4}>{ internalIcon }{ name }</Heading>
      {
        addButtonLink && <GreenAddButton link={addButtonLink} />
      }
      { children }
    </StyledGroup>
  );
}

const StyledGroup = styled.div`
  position: relative;
  padding: ${defaultStyles.element.padding.small};
  padding-top: ${defaultStyles.element.padding.default};
  width: 100%;
  background-color: #fff;
  //border: 0.05rem solid ${defaultStyles.color.grey.dark};
  border-radius: ${defaultStyles.element.border.radius.default};
  box-shadow: ${defaultStyles.element.boxShadow};
  ${props => {
    if(props.pattern) {
      if(props.pattern.type === "lines") return css `
        background-color: #fff;
        background-image: repeating-linear-gradient(45deg, transparent, transparent 1rem, ${props.pattern.color} 1rem, ${props.pattern.color} 2rem); 
      `
    }
    if(props.maxContent) return css `
      height: max-content;
    `
    if(props.addButtonLink) return  css `
      position: relative;
      > button {
        position: absolute;
        top: ${defaultStyles.element.padding.default};
        right: ${defaultStyles.element.padding.default};
      }
    `
  }}
  h4 {
    display: flex;
    align-items: center;
    gap: ${defaultStyles.element.padding.small};
  }
  label.groupLabel {
    padding: 0 1rem 0 0;
    position: absolute;
    top: -.7rem;
    background-color: #fff;
  }
`