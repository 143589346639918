//External
import {
  useEffect,
  useState
} from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import axios from "axios";

//Functions & Vars
import defaultStyles from "../../styles/defaultStyles";
import isUserAuthenticated from "../../lib/isUserAuthenticated";

//Components
import Heading from "../../components/ui/Heading";
import Grid from "../../components/helper/Grid";
import SubmitButton from "../../components/form/SubmitButton";
import Cookies from "universal-cookie";
import Message from "../../components/ui/Message";
import ControlledTextInput from "../../components/form/controlled/TextInput";
import ControlledPasswordInput from "../../components/form/controlled/PasswordInput";

export default function Login() {

  const navigate = useNavigate()

  const [userData, setUserData] = useState({
    username: "",
    password: ""
  })
  const [isNotLoggedIn, setIsNotLoggedIn] = useState(false)
  const [message, setMessage] = useState(<></>)

  function updateState(objKey, value) {
    setUserData({
      ...userData,
      [objKey]: value
    })
  }

  useEffect(() => {
    isUserAuthenticated(user => {
      if(user) return navigate("/")
      setIsNotLoggedIn(true)
    })
  }, [navigate])

  function onSubmit(e) {
    e.preventDefault()
    axios
      .post(`${process.env.REACT_APP_API_URL}/login`, {
        username: userData.username,
        password: userData.password
      })
      .then(res => {
        if(res.data.err) {
          updateState("password", "")
          setMessage(<Message type={"error"} title={defaultStyles.messages.login.error} message={res.data.err} />)
        }
        const token = res.data.data
        const cookie = new Cookies()
        cookie.set("appToken", JSON.stringify(token), {
          path: "/",
          sameSite: true
        })
        navigate("/dashboard")
      })
  }
  if(isNotLoggedIn) return (
    <StyledLogin>
      <span className={"logo"}>{ `//nlpm` }</span>
      <form onSubmit={onSubmit}>
        <Heading type={2}>Login</Heading>
        { message }
        <Grid columns={"1fr"} gap={".5rem"}>
          <ControlledTextInput name={"Benutzername"} value={userData.username} onValueChange={(newValue) => updateState("username", newValue)} />
          <ControlledPasswordInput name={"Passwort"} value={userData.password} onValueChange={(newValue) => updateState("password", newValue)} />
          <SubmitButton style={{marginTop: ".5rem"}}>Einloggen</SubmitButton>
        </Grid>
      </form>
    </StyledLogin>
  )
  return null
}

const StyledLogin = styled.div `
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url("/images/login_background.jpg") no-repeat;
  background-position: center;
  background-size: cover;
  span.logo {
    position: absolute;
    bottom: 2rem;
    left: 2rem;
    font-size: 4rem;
    font-weight: 600;
    color: #fff;
    text-shadow: 0 0 ${defaultStyles.element.padding.small} black;
    font-style: italic;
  }
  > form {
    width: 30rem;
    padding: ${defaultStyles.element.padding.default};
    border-radius: ${defaultStyles.element.border.radius.default};
    box-shadow: ${defaultStyles.element.boxShadow};
    background-color: #fff;
  }
`