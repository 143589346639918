import axios from "axios";
import {redirect} from "react-router-dom";

export default async function userDetailsLoader({params}) {
  const user = await axios
    .get(`${process.env.REACT_APP_API_URL}/admin/users/${params.userId}`)
    .then(res => {
      return res.data.data
    })
  if(Array.isArray(user)) return redirect("/404")
  return {
    user
  }
}