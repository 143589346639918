//External
import { Link } from "react-router-dom";

//Functions & Vars


//Components
import Icon from "../../helper/Icon";


export default function TableContentRow(props) {

  const {
    status,
    content,
    edit,
    view
  } = props;

  return (
    <tr>
      {
        status && <td className={"status"}><span className={"status"} title={status[1]} style={{ backgroundColor: status[0] }}></span></td>
      }
      {
        content.map((item, index) => {
          if(Array.isArray(item)) {
            let text = item[0]
            if(item[1].strike) text = <strike>{text}</strike>
            if(item[1].link) {
              return <td key={index}><Link to={item[1].link}>{ text }</Link></td>
            }
            else {
              return <td key={index}>{ text }</td>
            }
          }
          else {
            return <td key={index}>{ item }</td>
          }
        })
      }
      {
        edit && <td className={"edit"} title={"Bearbeiten"}><Link to={edit} className={"edit"}><Icon icon={"edit"} size={"1.5rem"} color={"#000"} /></Link></td>
      }
      {
        view && <td className={"edit"} title={"Anzeigen"}><Link to={view} className={"edit"}><Icon icon={"show"} size={"1.5rem"} color={"#000"} /></Link></td>
      }
    </tr>
  );
}