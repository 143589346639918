//External


//Functions & Vars
import StyledInput from "../../../styles/StyledInput";
import generateUniqueElementId from "../../../lib/generateUniqueElementId";

//Components


export default function ControlledDateInput(props) {

  const {
    name,
    value,
    disabled
  } = props;

  function innerOnChange(e) {
    e.preventDefault()
    console.log(new Date(e.target.value))
    props.onValueChange(new Date(e.target.value))
  }

  const date = new Date(value)
  let month = date.getUTCMonth()+1
  let day = date.getUTCDate()
  if(month < 10) month = "0" + month
  if(day < 10) day = "0" + day
  const formattedDateValue = `${date.getUTCFullYear()}-${month}-${day}`

  const elementId = generateUniqueElementId()
  return (
    <StyledInput>
      <label
        htmlFor={elementId}
      >{ name }</label>
      <input
        id={elementId}
        type={"date"}
        placeholder={"Kein Datum gewählt"}
        defaultValue={formattedDateValue}
        onChange={innerOnChange}
        disabled={disabled}
      />
    </StyledInput>
  )
}