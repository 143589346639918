//External
import styled from "styled-components";

//Functions & Vars
import defaultStyles from "../../../styles/defaultStyles";

//Components


export default function PageNavBar(props) {

  const {
    name,
    noNav,
    children
  } = props;

  return (
    <StyledPageNavbar>
      <div className={"area"}>
        <span>{ name }</span>
      </div>
      {
        noNav === undefined && (
          <>
            <div className={"sepline"}><span></span></div>
            <div className={"navpoints"}>
              { children }
            </div>
          </>
        )
      }
    </StyledPageNavbar>
  );
}

const StyledPageNavbar = styled.div `
  display: grid;
  position: fixed;
  z-index: 1;
  grid-template-columns: max-content 1rem 1fr;
  grid-gap: ${defaultStyles.element.padding.default};
  width: 100%;
  height: 4rem;
  //margin-bottom: ${defaultStyles.element.padding.small};
  box-shadow: 0 .125rem .125rem -.125rem rgba(0, 0, 0, 0.5);
  padding: ${defaultStyles.element.padding.small} 0;
  background-color: #fff;
  > div.area {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: ${defaultStyles.element.padding.default};
    font-size: 1.5rem;
    font-weight: 600;
  }
  > div.sepline {
    display: flex;
    justify-content: center;
    align-items: center;
    > span {
      display: inline-block;
      height: 80%;
      border-left: .1rem solid ${defaultStyles.color.grey.light};
    }
  }
  > div.navpoints {
    display: flex;
    align-items: center;
    gap: ${defaultStyles.element.padding.default};
  }
`