const defaultStyles = {
  layout: {
    header: {
      height: "4rem"
    },
    nav: {
      width: "6rem"
    }
  },
  element: {
    padding: {
      default: "1rem",
      small: ".5rem"
    },
    border: {
      default: `1px solid #7c7c7c`,
      focus: `1px solid #130f40`,
      radius: {
        default: ".25rem",
        small: ".15rem"
      }
    },
    outline: `2px solid rgba(19,15,64,0.25)`,
    boxShadow: "0 0 .25rem 0 rgba(0, 0, 0, .25)",
    transition: "all .15s ease-in-out"
  },
  color: {
    theme: {
      //default: "#130f40",
      default: "#af1e1c",
      //hover: "#2c228f",
      hover: "#8c1615",
      alpha05: "rgba(19,15,64,0.5)",
      alpha: "rgba(19,15,64,0.25)",
    },
    grey: {
      dark: "#7c7c7c",
      light: "#b6b6b6",
      disabled: "rgb(230, 230, 230)",
      disabledLight: "rgb(245, 245, 245)"
    },
    green: {
      light: "#2ecc71",
      dark: "#27ae60"
    },
    red: {
      light: "#e74c3c",
      dark: "#c0392b",
      alpha: "rgba(192, 57, 43, 0.25)"
    },
    purple: {
      light: "#9b59b6",
      dark: "#8e44ad"
    },
    orange: {
      light: "#e67e22",
      dark: "#d35400"
    },
    turquoise: {
      light: "#1abc9c",
      dark: "#16a085"
    }
  },
  messages: {
    login: {
      error: "Fehler beim Login:"
    },
    save: {
      success: "Die Daten wurden erfolgreich gespeichert!",
      error: "Beim Speichern der Daten ist ein Fehler aufgetreten:"
    },
    delete: {
      success: "Die Daten wurden erfolgreich gelöscht!",
      error: "Beim Löschen der Daten ist ein Fehler aufgereten:"
    }
  }
}

export default defaultStyles