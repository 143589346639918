import {Component, forwardRef} from "react";

import generateUniqueElementId from "../../lib/generateUniqueElementId";
import StyledInput from "../../styles/StyledInput"
import Icon from "../helper/Icon";

class TextInput extends Component {
  componentDidMount() {
    if(this.props.initialValue) this.props.innerRef.current.value = this.props.initialValue
  }
  onCopyContent = () => {
    navigator.clipboard
      .writeText(this.props.innerRef.current.value)
      .then(() => {
        return alert("Link kopiert")
      })
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if(prevProps.initialValue !== this.props.initialValue) {
      this.props.innerRef.current.value = this.props.initialValue
    }
  }

  render() {
    const elementId = generateUniqueElementId()
    if(this.props.onKeyDown) {
      return (
        <StyledInput canCopy={this.props.canCopy}>
          <label
            htmlFor={elementId}
          >{ this.props.name }</label>
          <input
            id={elementId}
            type={"text"}
            placeholder={this.props.name}
            ref={this.props.innerRef}
            onKeyDown={(e) => this.props.onKeyDown(e)}
            disabled={this.props.disabled}
          />
          {
            this.props.canCopy && (
              <span onClick={() => this.onCopyContent()}><Icon icon={"link"} size={"1.25rem"} color={"#000"} /></span>
            )
          }
        </StyledInput>
      );
    }
    else {
      return (
        <StyledInput canCopy={this.props.canCopy}>
          <label
            htmlFor={elementId}
          >{ this.props.name }</label>
          <input
            id={elementId}
            type={"text"}
            placeholder={this.props.name}
            ref={this.props.innerRef}
            disabled={this.props.disabled}
          />
          {
            this.props.canCopy && (
              <span onClick={() => this.onCopyContent()}><Icon icon={"link"} size={"1.25rem"} color={"#000"} /></span>
            )
          }
        </StyledInput>
      );
    }
  }
}

export default forwardRef((props, ref) => {
  return <TextInput innerRef={ref} {...props} />
})