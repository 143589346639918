import axios from "axios";
import getDataFromAPIResponse from "../../../lib/getDataFromAPIResponse";

export default async function invoicesLoader({ params }) {
  const clientDataPromise = axios
    .get(`${process.env.REACT_APP_API_URL}/invoices/${params.invoiceClientId}/${params.checkObjectId}`)
    .then(res => {
      const { err, data } = getDataFromAPIResponse(res)
      return data
    })
  const productsPromise = axios
    .get(`${process.env.REACT_APP_API_URL}/products`)
    .then(res => {
      const { err, data } = getDataFromAPIResponse(res)
      return data
    })
  const categoriesPromise = axios
    .get(`${process.env.REACT_APP_API_URL}/products/categories`)
    .then(res => {
      const { err, data } = getDataFromAPIResponse(res)
      return data
    })
  const billingTypesPromise = axios
    .get(`${process.env.REACT_APP_API_URL}/products/billingTypes`)
    .then(res => {
      const { err, data } = getDataFromAPIResponse(res)
      return data
    })

  const [
    clientAndObjectData,
    products,
    categories,
    billingTypes
  ] = await Promise.all([clientDataPromise, productsPromise, categoriesPromise, billingTypesPromise])

  console.log(clientAndObjectData)

  return {
    clientAndObjectData,
    products,
    categories,
    billingTypes
  }
}