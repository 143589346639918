//External
import { useLoaderData } from "react-router-dom"

//Functions & Vars
import defaultStyles from "../../styles/defaultStyles";

//Components
import Table from "../../components/ui/table/Table";
import TableContentRow from "../../components/ui/table/TableContentRow";
import TableHeadRow from "../../components/ui/table/TableHeadRow";
import SubPage from "../../components/ui/SubPage";
import ActionBar from "../../components/ui/action_bar/ActionBar";
import ActionButton from "../../components/ui/action_bar/ActionButton";
import Group from "../../components/ui/Group";


export default function UserManagement() {
  const users = useLoaderData()
  return (
    <SubPage name={"Benutzerverwaltung"}>
      <ActionBar>
        <ActionButton icon={"add"} title={"Neuen Benutzer erstellen"} to={"erstellen"} />
      </ActionBar>
      <Group name={"Benutzer"} icon={"group"}>
        <Table>
          <TableHeadRow status columns={[
            "Benutzername",
            "Vorname",
            "Nachname"
          ]} edit />
          <tbody>
          {
            users.map((user, index) => {
              let statusColor = [];
              if(user.active) statusColor = [defaultStyles.color.green.dark, "Benutzer ist aktiv"];
              else statusColor = [defaultStyles.color.red.dark, "Benutzer ist inaktiv"];
              return <TableContentRow key={index} status={statusColor} content={[[user.username, { link: `${user.id}` }], user.prename, user.surname]} edit={`${user.id}`} />
            })
          }
          </tbody>
        </Table>
      </Group>
    </SubPage>
  )
}