//External
import {
  Component,
  createRef,
  forwardRef
} from "react";
import styled from "styled-components";

//Functions & Vars
import defaultStyles from "../../styles/defaultStyles";

//Components
import Grid from "../helper/Grid";
import TextInput from "./TextInput";
import Icon from "../helper/Icon";
import Heading from "../ui/Heading";
import PersonItem from "../ui/list_items/PersonItem";

class AddItem extends Component {
  constructor(props) {
    super(props);
    this.textItemRef = createRef()
    this.state = {
      items: this.props.initalValue
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(prevProps.initalValue !== this.props.initalValue) {
      this.setState({
        items: this.props.initalValue
      })
      this.props.innerRef.current = this.props.initalValue
    }
  }

  onItemAdd = () => {
    if(this.textItemRef.current.value.length < 3) return
    let newItemsArray = this.state.items
    newItemsArray.push(this.textItemRef.current.value)
    this.textItemRef.current.value = ""
    this.props.innerRef.current = newItemsArray
    this.setState({
      items: newItemsArray
    })
  }

  onItemDelete = (arrayIndex) => {
    let currentItemsArray = this.state.items
    delete currentItemsArray[arrayIndex]
    let newItemsArray = []
    currentItemsArray.forEach(item => {
      newItemsArray.push(item)
    })
    this.props.innerRef.current = newItemsArray
    this.setState({
      items: newItemsArray
    })
  }

  onKeyPress = (e) => {
    if(e.key === "Enter") return this.onItemAdd()
  }

  render() {
    return (
      <StyledAddItem>
        <Heading type={4}>{ this.props.name }</Heading>
        <Grid columns={"1fr max-content"} gap={defaultStyles.element.padding.small} verticalAlign={"end"}>
          <TextInput name={"Name (und ggf. Rolle)"} ref={this.textItemRef} onKeyDown={(e) => this.onKeyPress(e)} />
          <button onClick={() => this.onItemAdd()}><Icon icon={"add"} size={"1.5rem"} color={"#fff"} /></button>
        </Grid>
        <ul>
        {
          this.state.items.map((item, index) => {
            return (
              <PersonItem
                key={index}
                name={item}
                onDelete={() => this.onItemDelete(index)}
              />
              /*
              <li key={index}>
                <span>{ item }</span>
                <DeleteActionIcon size={"1.25rem"} color={"#000"} onClick={() => this.onItemDelete(index)} />
              </li>
               */
            )
          })
        }
        </ul>
      </StyledAddItem>
    );
  }
}

export default forwardRef((props, ref) => {
  return <AddItem innerRef={ref} {...props} />
})

const StyledAddItem = styled.div `

  width: 100%;
    //background-color: ${defaultStyles.color.grey.disabled};
  padding: ${defaultStyles.element.padding.small};
  border: 0.05rem solid ${defaultStyles.color.grey.dark};
  border-radius: ${defaultStyles.element.border.radius.small};

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2rem;
    height: 2rem;
    border: 0;
    border-radius: 50%;
    background-color: ${defaultStyles.color.green.dark};
    cursor: pointer;
  }

  ul {
    display: grid;
    grid-gap: ${defaultStyles.element.padding.small};
    margin: 0;
    padding: ${defaultStyles.element.padding.small} 0 0 0;

    li {
      display: grid;
      grid-template-columns: 1fr max-content;
      padding: ${defaultStyles.element.padding.small};
      grid-gap: ${defaultStyles.element.padding.small};
      background-color: #f4f6f8;
      box-shadow: ${defaultStyles.element.boxShadow};
      border-radius: ${defaultStyles.element.border.radius.small};
      list-style: none;
    }
  }
`