//External
import { useState } from "react";
import { useRouteLoaderData } from "react-router-dom";
import axios from "axios";

//Functions & Vars
import updateState from "../lib/updateState";
import defaultStyles from "../styles/defaultStyles";
import getDataFromAPIResponse from "../lib/getDataFromAPIResponse";

//Components
import Grid from "../components/helper/Grid";
import PageNavBar from "../components/ui/page_navigation/PageNavBar";
import SubPage from "../components/ui/SubPage";
import Group from "../components/ui/Group";
import ActionButton from "../components/ui/action_bar/ActionButton";
import ActionBar from "../components/ui/action_bar/ActionBar";
import Message from "../components/ui/Message";
import passwordCheckFailed from "../lib/checkPassword";
import ControlledTextInput from "../components/form/controlled/TextInput";
import ControlledPasswordInput from "../components/form/controlled/PasswordInput";


export default function MyProfile() {
  const user = useRouteLoaderData("root")

  const [userData, setUserData] = useState({
    username: user.username,
    prename: user.prename,
    surname: user.surname,
    email: user.email,
    oldPassword: "",
    newPassword: "",
    newPasswordRepeat: ""
  })

  const [message, setMessage] = useState(<></>)



  function onSubmit() {
    let passwordFailMessage = passwordCheckFailed(userData.newPassword, userData.newPasswordRepeat)
    if(passwordFailMessage)
      return setMessage(<Message type={"error"} title={defaultStyles.messages.save.error} message={passwordFailMessage} />)
    passwordFailMessage = passwordCheckFailed(userData.oldPassword, userData.oldPassword)
    if(passwordFailMessage)
      return setMessage(<Message type={"error"} title={defaultStyles.messages.save.error} message={passwordFailMessage} />)
    axios
      .patch(`${process.env.REACT_APP_API_URL}/users/updateOwnData/${user.id}`, {
        prename: userData.prename,
        surname: userData.surname,
        email: userData.email,
        oldPassword: userData.oldPassword,
        newPassword: userData.newPassword,
        newPasswordRepeat: userData.newPasswordRepeat
      })
      .then(res => {
        const { err } = getDataFromAPIResponse(res)
        if(err) setMessage(<Message type={"error"} title={defaultStyles.messages.save.error} message={res.data.err} />)
        else {
          updateState(userData, setUserData, [
            ["oldPassword", ""],
            ["newPassword", ""],
            ["newPasswordRepeat", ""]
          ])
          setMessage(<Message type={"success"} title={defaultStyles.messages.save.success} />)
          setTimeout(() => {
            setMessage(<></>)
          }, 3000)
        }
      })
  }
  return (
    <>
      <PageNavBar name={"Mein Profil"} noNav />
      <SubPage>
        <ActionBar>
          <ActionButton icon={"save"} title={"Benutzer speichern"} onClick={onSubmit}></ActionButton>
        </ActionBar>
        { message }
        <Grid columns={"1fr 1fr"} gap={defaultStyles.element.padding.default}>
          <Group name={"Mein Profil"} icon={"person"}>
            <form onSubmit={onSubmit}>
              <Grid columns={"1fr"} gap={".5rem"}>
                <ControlledTextInput disabled name={"Benutzername"} value={userData.username} />

                <Grid columns={"1fr 1fr"} gap={defaultStyles.element.padding.default}>
                  <ControlledTextInput name={"Vorname"} value={userData.prename} onValueChange={(newValue) => updateState(userData, setUserData, "prename", newValue)} />
                  <ControlledTextInput name={"Nachname"} value={userData.surname} onValueChange={(newValue) => updateState(userData, setUserData, "surname", newValue)} />
                </Grid>
                <Grid columns={"1fr 1fr"} gap={defaultStyles.element.padding.default}>
                  <ControlledTextInput name={"E-Mail"} value={userData.email} onValueChange={(newValue) => updateState(userData, setUserData, "email", newValue)} />
                </Grid>
              </Grid>
            </form>
          </Group>
          <Group name={"Passwort"} icon={"lock"} maxContent>
            <Grid columns={"1fr"} gap={".5rem"}>
              <ControlledPasswordInput name={"Altes Passwort"} value={userData.oldPassword} onValueChange={(newValue) => updateState(userData, setUserData, "oldPassword", newValue)} />
              <ControlledPasswordInput name={"Neues Passwort"} value={userData.newPassword} onValueChange={(newValue) => updateState(userData, setUserData, "newPassword", newValue)} />
              <ControlledPasswordInput name={"Neues Passwort wiederholen"} value={userData.newPasswordRepeat} onValueChange={(newValue) => updateState(userData, setUserData, "newPasswordRepeat", newValue)} />
            </Grid>
          </Group>
        </Grid>
      </SubPage>
    </>

  )
}