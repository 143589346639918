import SubPage from "../../../components/ui/SubPage";
import ActionBar from "../../../components/ui/action_bar/ActionBar";
import ActionButton from "../../../components/ui/action_bar/ActionButton";
import Group from "../../../components/ui/Group";
import Grid from "../../../components/helper/Grid";
import ControlledTextInput from "../../../components/form/controlled/TextInput";
import defaultStyles from "../../../styles/defaultStyles";
import updateState from "../../../lib/updateState";
import {useLocation, useNavigate, useParams, useLoaderData} from "react-router-dom";
import {useState, useEffect} from "react";
import ControlledDateInput from "../../../components/form/controlled/DateInput";
import ControlledCheckbox from "../../../components/form/controlled/Checkbox";
import ControlledSelect from "../../../components/form/controlled/Select";
import axios from "axios";
import getDataFromAPIResponse from "../../../lib/getDataFromAPIResponse";
import SubmitButton from "../../../components/form/SubmitButton";
import Message from "../../../components/ui/Message";
import TableHeadRow from "../../../components/ui/table/TableHeadRow";
import Table from "../../../components/ui/table/Table";
import TableContentRow from "../../../components/ui/table/TableContentRow";
import Form from "../../../components/form/Form";
import {convertToDateInput} from "../../../lib/convertDate";
import TextArea from "../../../components/form/controlled/TextArea";
import DateHelper from "../../../lib/DateHelper";
import throwPageMessage from "../../../lib/throwPageMessage";

export default function CheckObjectDetails() {

  const [message, setMessage] = useState(<></>)

  const params = useParams()
  console.log(params)
  const navigate = useNavigate()
  const { state } = useLocation();
  let afterNewEntrySave = false;
  if(state && state.afterNewEntrySave) {
    afterNewEntrySave = true;
  }

  useEffect(() => {
    if(afterNewEntrySave)
      throwPageMessage(setMessage, "success", "Prüfobjekt erfolgreich gespeichert!")
  }, [afterNewEntrySave])

  const pathname = useLocation().pathname;
  let initialCheckObjectData;
  let newEntry = false;
  if(pathname.includes("/neu")) newEntry = true;

  const checkObjectLoaderData = useLoaderData();
  if(!checkObjectLoaderData || newEntry) {
    initialCheckObjectData = {
      name: "",
      address: {
        street: "",
        number: "",
        postcode: "",
        city: "",
        country: 0
      },
      contact: {
        useMainContact: true,
        gender: 0,
        preName: "",
        surName: "",
        email: ""
      },
      notice: "",
      maintenance: {
        nextDate: "",
        email: "",
        sendCopy: false
      },
      invoiceSettings: {
        useCheckObjectAddress: false,
        useContactInInvoiceAddress: true,
        nextMaintenanceDateInInvoice: false
      },
      invoices: []
    }
  }
  else {
    initialCheckObjectData = checkObjectLoaderData
  }

  //State
  const [checkObjectData, setCheckObjectData] = useState(initialCheckObjectData)

  let checkObjectGroupName = "Neues Prüfobjekt";
  if(checkObjectData.name) checkObjectGroupName = checkObjectData.name

  function onCheckObjectSave() {
    //Checking for Errors
    const errorMessages = []
    if(!checkObjectData.name.length) errorMessages.push("Für das Prüfobjekt wurde kein Name angegeben!")
    if(!checkObjectData.address.street.length) errorMessages.push("Für das Prüfobjekt wurde keine Straße angeben!")
    if(!checkObjectData.address.number.length) errorMessages.push("Für das Prüfobjekt wurde keine Hausnummer angegeben!")
    if(!checkObjectData.address.postcode.length) errorMessages.push("Für das Prüfobjekt wurde keine Postleitzahl angegeben!")
    if(!checkObjectData.address.city.length) errorMessages.push("Für das Prüfobjekt wurde kein Ort angegeben!")
    if(!checkObjectData.address.country) errorMessages.push("Für das Prüfobjekt wurde kein Land angegeben!")
    if(!checkObjectData.contact.useMainContact) {
      if(!checkObjectData.contact.gender) errorMessages.push("Für den Ansprechpartner des Prüfobjekts wurde keine Anrede angegeben!")
      if(!checkObjectData.contact.preName.length) errorMessages.push("Für den Ansprechpartner des Prüfobjekts wurde kein Vorname angegeben!")
      if(!checkObjectData.contact.surName.length) errorMessages.push("Für den Ansprechpartner des Prüfobjekts wurde kein Nachname angegeben!")
      if(!checkObjectData.contact.email.length) errorMessages.push("Für den Ansprechpartner des Prüfobjekts wurde keine E-Mail-Adresse angegeben!")
    }
    if(errorMessages.length) return setMessage(<Message type={"error"} title={defaultStyles.messages.save.error} messageList={errorMessages} />)
    //POST
    if(newEntry) {
      axios
        .post(`${process.env.REACT_APP_API_URL}/checkObjects/${params.invoiceClientId}/create`, checkObjectData)
        .then(res => {
          const { err, data } = getDataFromAPIResponse(res)
          if(err) return 0
          else return navigate(`../${data}`, {
            relative: "path" ,
            state: {
              afterNewEntrySave: true
            }
          })
        })
    }
    else {
      axios
        .patch(`${process.env.REACT_APP_API_URL}/checkObjects/${params.checkObjectId}`, checkObjectData)
        .then(res => {
          const { err } = getDataFromAPIResponse(res)
          if(err) return setMessage(<Message type={"error"} title={defaultStyles.messages.save.error} message={err} />)
          else {
            setTimeout(() => {
              setMessage(<></>)
            }, 3000)
            return setMessage(<Message type={"success"} title={defaultStyles.messages.save.success} />)
          }
        })
    }
  }

  function onCheckObjectDelete() {
    if(window.confirm(`Möchten Sie das Prüfobjekt "${checkObjectData.name}" wirklich löschen?`)) {
      axios
        .delete(`${process.env.REACT_APP_API_URL}/checkObjects/${params.checkObjectId}`)
        .then(res => {
          const { err, data } = getDataFromAPIResponse(res)
          if(err) return throwPageMessage(setMessage, "error", err)
          else {
            alert(`Das Prüfobjekt "${checkObjectData.name}" wurde erfolgreich gelöscht. Sie werden zum Rechnungsempfänger weitergeleitet.`)
            return navigate(`/verwaltung/rechnungsempfaenger/${params.invoiceClientId}`)
          }
        })
    }
  }

  function onNewInvoice() {
    return navigate(`rechnung/neu`, { relative: "path" })
  }

  return (
    <SubPage>
      <ActionBar>
        <ActionButton icon={"back"} title={"Zurück zum Rechnungsempfänger"} onClick={() => navigate(`/verwaltung/rechnungsempfaenger/${params.invoiceClientId}`)} />
        <ActionButton icon={"save"} title={"Speichern"} onClick={onCheckObjectSave}/>
        <ActionButton icon={"delete"} title={"Löschen"} onClick={onCheckObjectDelete} />
      </ActionBar>
      { message }
      <Grid columns={"2fr 1fr 2fr"} gap={defaultStyles.element.padding.default} maxContent>
        <Grid columns={"1fr"} gap={defaultStyles.element.padding.default} maxContent>
          <Group icon={"home"} name={checkObjectGroupName} maxContent>
            <Grid columns={"1fr"} gap={defaultStyles.element.padding.small}>
              <ControlledTextInput name={"Name"} value={checkObjectData.name} onValueChange={(newValue) => updateState(checkObjectData, setCheckObjectData, ["name"], newValue, true)} />
              <Grid columns={"5fr 1fr"} gap={defaultStyles.element.padding.small}>
                <ControlledTextInput name={"Straße"} value={checkObjectData.address.street} onValueChange={(newValue) => updateState(checkObjectData, setCheckObjectData, ["address", "street"], newValue, true)} />
                <ControlledTextInput name={"Hausnummer"} value={checkObjectData.address.number} onValueChange={(newValue) => updateState(checkObjectData, setCheckObjectData, ["address", "number"], newValue, true)} />
              </Grid>
              <Grid columns={"1fr 5fr"} gap={defaultStyles.element.padding.small}>
                <ControlledTextInput name={"PLZ"} value={checkObjectData.address.postcode} onValueChange={(newValue) => updateState(checkObjectData, setCheckObjectData, ["address", "postcode"], newValue, true)} />
                <ControlledTextInput name={"Ort"} value={checkObjectData.address.city} onValueChange={(newValue) => updateState(checkObjectData, setCheckObjectData, ["address", "city"], newValue, true)} />
              </Grid>
              <ControlledSelect name={"Land"} value={checkObjectData.address.country} onValueChange={(newValue) => updateState(checkObjectData, setCheckObjectData, ["address", "country"], newValue, true)}>
                <option value={1}>Deutschland</option>
                <option value={2}>Österreich</option>
              </ControlledSelect>
            </Grid>
          </Group>
          <Group name={"Anprechpartner (für Objekt, Rechnungsemfpänger)"} maxContent>
            <Grid columns={"1fr"} gap={defaultStyles.element.padding.small}>
              <ControlledCheckbox value={checkObjectData.contact.useMainContact} onValueChange={(newValue) => updateState(checkObjectData, setCheckObjectData, ["contact", "useMainContact"], newValue, true)}>Zentralen Ansprechpartner des Rechnungsempfängers verwenden</ControlledCheckbox>
              {
                !checkObjectData.contact.useMainContact && (
                  <Grid columns={"1fr"} gap={defaultStyles.element.padding.small}>
                    <Grid columns={"1fr 3fr 3fr"} gap={defaultStyles.element.padding.small}>
                      <ControlledSelect name={"Anrede"} value={checkObjectData.contact.gender} onValueChange={(newValue) => updateState(checkObjectData, setCheckObjectData, ["contact", "gender"], newValue, true)}>
                        <option value={1}>Herr</option>
                        <option value={2}>Frau</option>
                        <option value={3}>Keine Angabe</option>
                      </ControlledSelect>
                      <ControlledTextInput name={"Vorname"} value={checkObjectData.contact.preName} onValueChange={(newValue) => updateState(checkObjectData, setCheckObjectData, ["contact", "preName"], newValue, true)} />
                      <ControlledTextInput name={"Nachname"} value={checkObjectData.contact.surName} onValueChange={(newValue) => updateState(checkObjectData, setCheckObjectData, ["contact", "surName"], newValue, true)} />
                    </Grid>
                    <ControlledTextInput name={"E-Mail-Adresse"} value={checkObjectData.contact.email} onValueChange={(newValue) => updateState(checkObjectData, setCheckObjectData, ["contact", "email"], newValue, true)} />
                  </Grid>
                )
              }
            </Grid>
          </Group>
          <Group name={"Bemerkungen"}>
            <TextArea name={"Bemerkungen"} value={checkObjectData.notice} onValueChange={newValue => updateState(checkObjectData, setCheckObjectData, ["notice"], newValue, true)} />
          </Group>
        </Grid>
        <Grid columns={"1fr"} gap={defaultStyles.element.padding.default} maxContent>
          <Group name={"Nächster Wartungstermin"}>
            <ControlledDateInput name={"Datum"} value={convertToDateInput(checkObjectData.maintenance.nextDate)} onValueChange={(newValue) => updateState(checkObjectData, setCheckObjectData, ["maintenance", "nextDate"], newValue,true)} />
          </Group>
          <Group name={"Information über Wartungstermin"}>
            <Grid columns={"1fr"} gap={defaultStyles.element.padding.small}>
              <p>Nächster Wartungstermin: <span style={{ fontWeight: 600 }}>Keine Angabe</span></p>
              <ControlledTextInput name={"Zentrale E-Mail-Adresse"} value={checkObjectData.maintenance.email} onValueChange={newValue => updateState(checkObjectData, setCheckObjectData, ["maintenance", "email"], newValue, true)} />
              <ControlledCheckbox value={checkObjectData.maintenance.sendCopy} onValueChange={newValue => updateState(checkObjectData, setCheckObjectData, ["maintenance", "sendCopy"], newValue, true)}>E-Mail als Kopie an eigene E-Mail-Adresse senden</ControlledCheckbox>
              <p>Sie können eine E-Mail an eine zentrale Adresse senden, um über den nächsten Wartungstermin zu informieren (z.B. hausverwaltung@objekt.de)</p>
              <Form>
                <SubmitButton>E-Mail senden</SubmitButton>
              </Form>
            </Grid>
          </Group>
        </Grid>
        <Grid columns={"1fr"} gap={defaultStyles.element.padding.default} maxContent>
          <Group name={"Rechnungseinstellungen"}>
            <Grid columns={"1fr"} gap={defaultStyles.element.padding.small}>
              <ControlledCheckbox value={checkObjectData.invoiceSettings.useCheckObjectAddress} onValueChange={newValue => updateState(checkObjectData, setCheckObjectData, ["invoiceSettings", "useCheckObjectAddress"], newValue, true)}>Anschrift des Prüfobjekts als Rechnungsanschrift verwenden</ControlledCheckbox>
              <ControlledCheckbox value={checkObjectData.invoiceSettings.useContactInInvoiceAddress} onValueChange={newValue => updateState(checkObjectData, setCheckObjectData, ["invoiceSettings", "useContactInInvoiceAddress"], newValue, true)}>Ansprechpartner in Rechnungsanschrift verwenden</ControlledCheckbox>
              <ControlledCheckbox value={checkObjectData.invoiceSettings.nextMaintenanceDateInInvoice} onValueChange={newValue => updateState(checkObjectData, setCheckObjectData, ["invoiceSettings", "nextMaintenanceDateInInvoice"], newValue, true)}>Nächsten Wartungstermin auf Rechnung ausweisen</ControlledCheckbox>
            </Grid>
          </Group>
          {
            !newEntry && (
              <Group name={"Neue Rechnung"}>
                <Grid columns={"1fr"} gap={defaultStyles.element.padding.small}>
                  <Form onSubmit={onNewInvoice}>
                    <SubmitButton>Rechnung erstellen</SubmitButton>
                  </Form>
                </Grid>
              </Group>
            )
          }
          {
            !newEntry && (
              <Group name={"Rechnungen"}>
                <Grid columns={"1fr"} gap={defaultStyles.element.padding.small}>
                  <Table>
                    <TableHeadRow columns={["Rechnung", "Datum"]} view={true} />
                    <tbody>
                    {
                      checkObjectData.invoices.map((item, index) => {
                        const date = new DateHelper(item.date)
                        return <TableContentRow key={index} content={[item.invoiceId, date.getDateString()]} view={`/verwaltung/rechnungen/${item._id}`} />
                      })
                    }
                    </tbody>
                  </Table>
                </Grid>
              </Group>
            )
          }
        </Grid>
      </Grid>
    </SubPage>
  )
}