import { useRouteError } from "react-router-dom";
import SubPage from "../components/ui/SubPage";
import Message from "../components/ui/Message";

export default function ErrorElement() {
  const error = useRouteError()
  if(!error) return null;
  let errorMessage = ""
  if(error.data && error.data.message) errorMessage = error.data.message
  else if(error.message) errorMessage = error.message
  else errorMessage = "Unklare Fehlermeldung. Bitte Administrator kontaktieren."
  return (
    <SubPage>
      <Message type={"error"} title={"Fehler"} message={errorMessage} />
    </SubPage>
  )
}