//External
import {useRouteLoaderData} from "react-router-dom";

//Functions & Vars


//Components
import PageNavBar from "../components/ui/page_navigation/PageNavBar";
import SubPage from "../components/ui/SubPage";


export default function Dashboard() {
  const user = useRouteLoaderData("root")
  return (
    <>
      <PageNavBar name={"Dashboard"} noNav />
      <SubPage>
        <h2>Herzlich Willkommen, {user.prename}!</h2>
      </SubPage>
    </>
  )
}