//External


//Functions & Vars


//Components
import SubPage from "../../components/ui/SubPage";

export default function AdminSettings() {
  return (
    <SubPage name={"Einstellungen"}>

    </SubPage>
  )
}