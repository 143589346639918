//External


//Functions & Vars
import StyledInput from "../../../styles/StyledInput";

//Components


export default function ControlledSelect(props) {

  const {
    name,
    children,
    value,
    disabled
  } = props

  function innerOnChange(e) {
    e.preventDefault()
    if(isNaN(e.target.value)) return props.onValueChange(e.target.value)
    else return props.onValueChange(parseInt(e.target.value))
  }

  let optionChooseJSX = <option value={""}>Bitte wählen...</option>
  if(value) optionChooseJSX = <></>
  return (
    <StyledInput>
      <label>{ name }</label>
      <select value={value} onChange={innerOnChange} disabled={disabled}>
        { optionChooseJSX }
        { children }
      </select>
    </StyledInput>
  );
}