//External
import styled from "styled-components";

//Functions & Vars
import defaultStyles from "../../../styles/defaultStyles";

//Components
import Icon from "../../helper/Icon";
import DeleteActionIcon from "../action_icons/DeleteActionIcon";


export default function PersonItem(props) {

  const {
    email,
    phone,
    directDialing,
    download,
    name,
    onDelete,
    id
  } = props;

  let contactInformation = <></>

  if(email || phone || directDialing || download) {
    contactInformation = (
      <div>
        {
          email && <a href={`mailto:` + email}><Icon icon={"mail"} size={"1.25rem"} color={defaultStyles.color.theme.hover} />E-Mail</a>
        }
        {
          phone && <a href={`tel:` + phone}><Icon icon={"phone"} size={"1.25rem"} color={defaultStyles.color.theme.hover} />Anrufen</a>
        }
        {
          directDialing && <a href={`tel:` + directDialing}><Icon icon={"phone"} size={"1.25rem"} color={defaultStyles.color.theme.hover} />Anrufen</a>
        }
        {
          download && <a href={download} target={"_blank"} rel={"noreferrer"} ><Icon icon={"download"} size={"1.25rem"} color={defaultStyles.color.theme.hover} />Download</a>
        }
      </div>
    )
  }

  return (
    <StyledPersonItem>
      <div className={"contact"}>
        <span>{ name }</span>
        { contactInformation }
      </div>
      {
        onDelete && <DeleteActionIcon size={"1.25rem"} color={"#000"} onClick={() => onDelete(id)} />
      }
    </StyledPersonItem>
  );

}

const StyledPersonItem = styled.li `
  display: grid;
  grid-template-columns: 1fr max-content;
  padding: ${defaultStyles.element.padding.small};
  grid-gap: ${defaultStyles.element.padding.small};
  background-color: #f4f6f8;
  box-shadow: ${defaultStyles.element.boxShadow};
  border-radius: ${defaultStyles.element.border.radius.small};
  list-style: none;
  div.contact {
    display: grid;
    grid-template-columns: 1fr;
    gap: ${defaultStyles.element.padding.small};
    > span {
      font-weight: 600;
    }
    > div {
      display: flex;
      gap: ${defaultStyles.element.padding.default};
      > a {
        display: flex;
        align-items: center;
        gap: ${defaultStyles.element.padding.small};
        color: ${defaultStyles.color.theme.hover};
        text-decoration: none;
      } 
    }
  }
`