//External
import styled from "styled-components";

//Functions & Vars
import defaultStyles from "../../styles/defaultStyles";

//Components


export default function SubPage(props) {

  const {
    children
  } = props;

  return (
    <StyledPage id={"subpage"}>
      { children }
    </StyledPage>
  );
}

const StyledPage = styled.div `
  width: 100%;
  padding: ${defaultStyles.element.padding.default};
  height: calc(100vh - 8rem);
  margin-top: 4rem;
  overflow-y: auto;
`