import axios from "axios";
import getDataFromAPIResponse from "../../../lib/getDataFromAPIResponse";
import throwLoaderError from "../../../lib/throwLoaderError";

export default function checkObjectDetailsLoader({params}) {
  const { checkObjectId } = params;
  if(checkObjectId === "neu") return null;
  else return axios
    .get(`${process.env.REACT_APP_API_URL}/checkObjects/${params.checkObjectId}`)
    .then(res => {
      const { err, data } = getDataFromAPIResponse(res)
      if(err) return throwLoaderError(500, err)
      else if(!data) return throwLoaderError(404, "Kein Datensatz mit dieser ID gefunden!")
      else return data;
    })
}