//External
import styled from "styled-components";

//Functions & Vars
import defaultStyles from "../../../styles/defaultStyles";

//Components


export default function ActionBar(props) {

  const {
    children
  } = props;

  return (
    <StyledActionBar>
      { children }
    </StyledActionBar>
  );
}

const StyledActionBar = styled.div `
  display: flex;
  gap: 1rem;
  width: 100%;
  margin-bottom: ${defaultStyles.element.padding.default};
  > span {
    display: inline-block;
    height: 2.5rem;
    align-self: center;
    border-left: 0.1rem solid ${defaultStyles.color.grey.light};
  }
`