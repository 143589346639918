//External
import styled from "styled-components";

//Functions & Vars
import defaultStyles from "../../styles/defaultStyles";

//Components


export default function Heading(props) {

  const {
    type,
    children
  } = props;

  switch (type) {
    case 1: return <StyledHeading><h1>{ children }</h1></StyledHeading>
    case 2: return <StyledHeading><h2>{ children }</h2></StyledHeading>
    case 3: return <StyledHeading><h3>{ children }</h3></StyledHeading>
    case 4: return <StyledHeading><h4>{ children }</h4></StyledHeading>
    case 5: return <StyledHeading><h5>{ children }</h5></StyledHeading>
    case 6: return <StyledHeading><h6>{ children }</h6></StyledHeading>
    default: throw Error("Missing Prop: this.props.type is not defined!")
  }
}

const StyledHeading = styled.div `
  display: block;
  margin: 0 0 1rem 0;
  > h1, h2, h3, h4, h5, h6 {
    margin: ${defaultStyles.element.padding.default} 0;
    font-weight: 700;
    &:first-child {
      margin: 0;
    }
  }
  > h1, h2 {
    //font-weight: 200;
  }
  > h1 {
    font-size: 2rem;
  }
  > h2 {
    font-size: 1.75rem;
  }
`