import Message from "../components/ui/Message";
import styled from "styled-components";
import defaultStyles from "../styles/defaultStyles";

export default function NotFound() {
  return (
    <StyledErrorWrapper>
      <Message type={"error"} title={"Fehler"} message={"Die gesuchte Seite existiert nicht."} />
    </StyledErrorWrapper>
  )
}

const StyledErrorWrapper = styled.div`
  padding: ${defaultStyles.element.padding.default};
`