import axios from "axios";
import getDataFromAPIResponse from "../../../lib/getDataFromAPIResponse";

export default async function productsAndPricesLoader() {
  const categoriesPromise = axios
    .get(`${process.env.REACT_APP_API_URL}/products/categories`)
    .then(res => {
      const { err, data } = getDataFromAPIResponse(res)
      return data
    })
  const billingTypesPromise = axios
    .get(`${process.env.REACT_APP_API_URL}/products/billingTypes`)
    .then(res => {
      const { err, data } = getDataFromAPIResponse(res)
      return data
    })
  const productsPromise = axios
    .get(`${process.env.REACT_APP_API_URL}/products`)
    .then(res => {
      const { err, data } = getDataFromAPIResponse(res)
      return data
    })
  const [
    categories,
    billingTypes,
    products
  ] = await Promise.all([categoriesPromise, billingTypesPromise, productsPromise])
  return {
    categories,
    billingTypes,
    products
  }
}