//External
import { NavLink } from "react-router-dom";
import styled, {css} from "styled-components";

//Functions & Vars
import defaultStyles from "../../../styles/defaultStyles";

//Components


export default function NavTile(props) {

  const {
    to,
    children,
    disabled
  } = props;

  if(!disabled) {
    return (
      <StyledNavTile>
        <NavLink
          to={to}
          className={({ isActive }) =>
            isActive ? "active" : null
          }>
          { children }
        </NavLink>
      </StyledNavTile>
    );
  }
  else {
    return (
      <StyledNavTile disabled={true}>
        <a
          href={"#"}
          className={({ isActive }) =>
            isActive ? "active" : null
          }>
          { children }
        </a>
      </StyledNavTile>
    );
  }
}

const StyledNavTile = styled.div `
  > a {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: calc(${defaultStyles.element.padding.small} + .15rem);
    text-decoration: none;
    color: ${defaultStyles.color.grey.dark};
    font-weight: 600;
    border-radius: ${defaultStyles.element.border.radius.small};
    transition: ${defaultStyles.element.transition};
    &:hover {
      //background-color: ${defaultStyles.color.theme.hover};
      color: #000;
    }
    &.active {
      background-color: ${defaultStyles.color.theme.default};
      box-shadow: ${defaultStyles.element.boxShadow};
      color: #fff;
    }
  }
  ${props => {
    if(props.disabled) return css`
      a {
        cursor: not-allowed;
      }
    `;
  }}
`