//External
import styled from "styled-components";

//Functions & Vars
import defaultStyles from "../../../styles/defaultStyles";

//Components


export default function Table(props) {

  const {
    children
  } = props;

  return (
    <StyledTable>
      { children }
    </StyledTable>
  );
}

const StyledTable = styled.table `
  width: 100%;
  border-spacing: 0;
  border-collapse: separate;
  thead {
    background-color: ${defaultStyles.color.theme.default};
    th {
      color: #fff;
      text-align: left;
      &.status {
        width: 4rem;
        text-align: center;
      }
    }
  }
  tbody {
    tr {
      transition: ${defaultStyles.element.transition};
      background-color: ${defaultStyles.color.grey.disabledLight};
      &:nth-of-type(2n) {
        background-color: ${defaultStyles.color.grey.disabled};
      }
      &:hover {
        background-color: ${defaultStyles.color.theme.alpha};
      }
      td.status {
        display: flex;
        justify-content: center;
        padding-top: calc(${defaultStyles.element.padding.small} + .25rem);
      }
    }
  }
  tr {
    th, td {
      padding: ${defaultStyles.element.padding.small};
      &.edit, &.delete {
        width: 3rem;
        text-align: center;
        }
        span.status {
          display: flex;
          width: 1.25rem;
          height: 1.25rem;
          border-radius: ${defaultStyles.element.border.radius.small};
        }
      }
      a {
        text-decoration: none;
        color: #000;
        font-weight: 600;
        &.edit, &.delete {
          width: 1.75rem;
          height: 1.75rem;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: ${defaultStyles.element.border.radius.small};
          text-align: center;
          transition: ${defaultStyles.element.transition};
          > i {
            transition: ${defaultStyles.element.transition};
          }
          &:hover {
            background-color: ${defaultStyles.color.theme.default};
            > i {
              background-color: #fff !important;
            }
          }
        }
      }
    }
  }
`