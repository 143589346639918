import styled, {css} from "styled-components";
import defaultStyles from "../../styles/defaultStyles";

export default function SubmitButton(props)  {
  const {
    style,
    children,
    onClick,
    disabled
  } = props;

  function innerOnClickHandler(e) {
    e.preventDefault();
    props.onClick();
  }

  if(disabled) {
    return (
      <StyledSubmitButton style={style} disabled={true} >{ children }</StyledSubmitButton>
    );
  }
  else {
    if(!onClick) {
      return (
        <StyledSubmitButton style={style} type={"submit"} disabled={disabled}>{ children }</StyledSubmitButton>
      );
    }
    else {
      return (
        <StyledSubmitButton onClick={innerOnClickHandler} style={style} type={"submit"} disabled={disabled}>{ children }</StyledSubmitButton>
      );
    }
  }
}

const StyledSubmitButton = styled.button `
  height: 2.25rem;
  color: #fff;
  border: 0;
  border-radius: ${defaultStyles.element.border.radius.small};
  cursor: pointer;
  ${props => {
    if(!props.disabled) return css`
      background-color: ${defaultStyles.color.theme.default};
      &:hover {
        background-color: ${defaultStyles.color.theme.hover};
      }
    `;
    else return css`
      background-color: ${defaultStyles.color.grey.disabled};
      cursor: not-allowed;
      color: ${defaultStyles.color.theme.default};
    `;
  }}
`