//External
import styled, {css} from "styled-components";

//Functions & Vars
import defaultStyles from "./defaultStyles";

//Components


const StyledInput = styled.div `
  width: 100%;
  display: block;
  ${props => {
    if(props.canCopy) return css `
      display: grid;
      grid-template-columns: 1fr max-content;
      align-items: center;
    `
  }}
  > label {
    display: block;
    grid-column: 1/3;
    margin-bottom: .25rem;
    width: max-content;
    user-select: none;
  }
  > 
  input[type=text],
  input[type=password],
  input[type=date],
  input[type=datetime-local],
  input[type=number],
  textarea,
  select {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    width: 100%;
    height: 2rem;
    display: block;
    padding: 0 ${defaultStyles.element.padding.small};
    border: ${defaultStyles.element.border.default};
    border-radius: ${defaultStyles.element.border.radius.small};
    transition: ${defaultStyles.element.transition};
    font-size: 1rem;
    background-color: #fff;
    outline: none;
    resize: none;
    &:focus {
      border: ${defaultStyles.element.border.focus};
      outline: ${defaultStyles.element.outline};
    }
    &:disabled {
      background-color: ${defaultStyles.color.grey.disabled};
    }
  }
  textarea {
    min-height: 10rem;
    padding: ${defaultStyles.element.padding.small};
    height: fit-content;
  }
  > span {
    display: block;
    margin-left: ${defaultStyles.element.padding.small};
    cursor: pointer;
  }
`

export default StyledInput