import SubPage from "../../../components/ui/SubPage";
import ActionBar from "../../../components/ui/action_bar/ActionBar";
import ActionButton from "../../../components/ui/action_bar/ActionButton";
import Group from "../../../components/ui/Group";
import defaultStyles from "../../../styles/defaultStyles";
import Grid from "../../../components/helper/Grid";
import ControlledTextInput from "../../../components/form/controlled/TextInput";
import ControlledPriceInput from "../../../components/form/controlled/PriceInput";
import SubmitButton from "../../../components/form/SubmitButton";
import Form from "../../../components/form/Form";
import {useState} from "react";
import updateState from "../../../lib/updateState";
import ControlledSelect from "../../../components/form/controlled/Select";
import Heading from "../../../components/ui/Heading";
import Table from "../../../components/ui/table/Table";
import TableHeadRow from "../../../components/ui/table/TableHeadRow";
import ControlledNumericInput from "../../../components/form/controlled/NumericInput";
import Message from "../../../components/ui/Message";
import axios from "axios";
import getDataFromAPIResponse from "../../../lib/getDataFromAPIResponse";
import TableContentRow from "../../../components/ui/table/TableContentRow";
import {useLoaderData} from "react-router-dom";

export default function ProductsPrices() {

  const {
    categories,
    billingTypes,
    products
  } = useLoaderData()

  const [newProductItem, setNewProductItem] = useState({
    name: "",
    price: 0.00,
    category: 0,
    billingType: 0
  })
  const [productItems, setProductItems] = useState(products)
  const [message, setMessage] = useState(<></>)

  function onNewProductAdd() {
    const errorMessageArray = []
    if(!newProductItem.name.length) errorMessageArray.push("Das Produkt besitzt keinen Namen!")
    if(!newProductItem.category) errorMessageArray.push("Dem Produkt wurde keine Kategorie zugeordnet!")
    if(!newProductItem.billingType) errorMessageArray.push("Dem Produkt wurde kein Abrechnungstyp zugeordnet!")
    if(errorMessageArray.length) return setMessage(<Message type={"error"} title={defaultStyles.messages.save.error} messageList={errorMessageArray}/>)
    let price = newProductItem.price.replace(",", ".")
    axios
      .post(`${process.env.REACT_APP_API_URL}/products/create`, {
        name: newProductItem.name,
        category: newProductItem.category,
        billingType: newProductItem.billingType,
        price: parseFloat(price).toFixed(2)
      })
      .then(res => {
        const { data, err } = getDataFromAPIResponse(res)
        if(!err) {
          const newEntry = {
            id: data,
            name: newProductItem.name,
            category: newProductItem.category,
            billingType: newProductItem.billingType,
            price: newProductItem.price
          }
          const newArray = productItems
          newArray.push(newEntry)
          setProductItems(newArray)
          setNewProductItem({
            name: "",
            price: 0.00,
            category: 0,
            billingType: 0
          })
          setMessage(<Message type={"success"} title={defaultStyles.messages.save.success}/>)
        }
        else setMessage(<Message type={"error"} title={defaultStyles.messages.save.error}/>)
        return setTimeout(() => {
          setMessage(<></>)
        }, 1000)
      })
  }

  return (
    <SubPage>

      { message }
      <Grid columns={"1fr 3fr"} gap={defaultStyles.element.padding.default} maxContent>
        <Group name={"Neues Produkt hinzufügen"} maxContent>
          <Form onSubmit={onNewProductAdd}>
            <Grid columns={"1fr"} gap={defaultStyles.element.padding.small}>
              <ControlledTextInput name={"Produktname"} value={newProductItem.name} onValueChange={(newValue) => updateState(newProductItem, setNewProductItem, ["name"], newValue, true)} />
              <ControlledSelect name={"Kategorie"} value={newProductItem.category} onValueChange={(newValue) => updateState(newProductItem, setNewProductItem, ["category"], newValue, true)}>
                {
                  categories.map((item, index) => {
                    return <option key={index} value={item.id}>{item.name}</option>
                  })
                }
              </ControlledSelect>
              <ControlledSelect name={"Abrechnung"} value={newProductItem.billingType} onValueChange={(newValue) => updateState(newProductItem, setNewProductItem, ["billingType"], newValue, true)}>
                {
                  billingTypes.map((item, index) => {
                    return <option key={index} value={item.id}>{item.name}</option>
                  })
                }
              </ControlledSelect>
              <ControlledPriceInput name={"Einzelpreis"} value={newProductItem.price} onValueChange={(newValue) => updateState(newProductItem, setNewProductItem, ["price"], newValue, true)} />
              <SubmitButton>Speichern</SubmitButton>
            </Grid>
          </Form>
        </Group>
        <Grid columns={"1fr"} gap={defaultStyles.element.padding.default} maxContent>
          {
            categories.map((category, index) => {
              return (
                <Group key={index} name={`Produkte (${category.name})`} maxContent>
                  <Table>
                    <TableHeadRow columns={["Name", "Abrechnung", "Einzelpreis"]} />
                    <tbody>
                    {
                      productItems
                        .filter((item) => item.category === category.id )
                        .map((item, index) => {
                          return <TableContentRow key={index} content={[item.name, billingTypes.find(billingType => billingType.id === item.billingType).name, item.price.toFixed(2)+"€"]} />
                        })
                    }
                    </tbody>
                  </Table>
                </Group>
              )
            })
          }
        </Grid>
      </Grid>
    </SubPage>
  )
}