//External


//Functions & Vars
import generateUniqueElementId from "../../../lib/generateUniqueElementId";
import StyledInput from "../../../styles/StyledInput"

//Components

export default function ControlledPasswordInput(props) {

  const {
    name,
    value
  } = props;

  function innerOnChange(e) {
    e.preventDefault()
    props.onValueChange(e.target.value)
  }

  const elementId = generateUniqueElementId()
  return (
    <StyledInput>
      <label
        htmlFor={elementId}
      >{ name }</label>
      <input
        id={elementId}
        type={"password"}
        placeholder={name}
        value={value}
        onChange={innerOnChange}
      />
    </StyledInput>
  );
}