//External
import styled from "styled-components";

//Functions & Vars
import defaultStyles from "../../styles/defaultStyles";

//Components
import Icon from "../helper/Icon";
import List from "./List";


export default function Message(props) {

  const {
    type,
    title,
    message,
    messageList
  } = props;

  //Set Icon for MessageType
  let icon = <></>
  const time = new Date()
  let hours = time.getHours()
  let minutes = time.getMinutes()
  let seconds = time.getSeconds()
  if(hours < 10) hours = "0" + hours
  if(minutes < 10) minutes = "0" + minutes
  if(seconds < 10) seconds = "0" + seconds
  const timeString = `${hours}:${minutes}:${seconds} Uhr`
  if(type === "success") icon = <Icon icon={"check"} size={"1.5rem"} color={"#fff"} />
  else if(type === "info") icon = <Icon icon={"info"} size={"1.5rem"} color={"#fff"} />
  else if(type === "error") icon = <Icon icon={"error"} size={"1.5rem"} color={"#fff"} />

  //RENDER
  return (
    <StyledMessage type={type}>
      { icon }
      <div>
        <p>{ title }</p>
        {
          message && <p>{ message }</p>
        }
        {
          messageList && (
            <List>
              {
                messageList.map((item, index) => {
                  return <li key={index}>{item}</li>
                })
              }
            </List>
          )
        }
      </div>
      <span className={"time"}>{ timeString }</span>
    </StyledMessage>
  );
}

const StyledMessage = styled.div `
  position: relative;
  display: grid;
  grid-template-columns: 1.5rem 1fr;
  grid-gap: 1rem;
  width: 100%;
  padding: ${defaultStyles.element.padding.default};
  margin-bottom: ${defaultStyles.element.padding.small};
  background-color: ${props => {
    if(props.type === "success") return defaultStyles.color.green.dark
    else if(props.type === "info") return "#130f40"
    else if(props.type === "error") return defaultStyles.color.red.dark
  }};
  border: .05rem solid ${props => {
    if(props.type === "success") return defaultStyles.color.green.light
    else if(props.type === "info") return "#130f40"
    else if(props.type === "error") return defaultStyles.color.red.light
    
  }};
  border-radius: ${defaultStyles.element.border.radius.small};
  box-shadow: ${defaultStyles.element.boxShadow};
  color: #fff;
  p {
    font-weight: 600;
    margin: 0;
    &:nth-of-type(2) {
      font-weight: 400;
      margin-top: ${defaultStyles.element.padding.small};
    }
  }
  span.time {
    position: absolute;
    top: .25rem;
    right: .4rem;
    font-size: .75rem;
  }
`